bodY {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.center {
    height: 30vh;
    width: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
}

.wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, cyan, #fff);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
}

.wave:nth-child(2) {
    animation-delay: 0.1s;
}

.wave:nth-child(3) {
    animation-delay: 0.2s;
}

.wave:nth-child(4) {
    animation-delay: 0.3s;
}

.wave:nth-child(5) {
    animation-delay: 0.4s;
}

.wave:nth-child(6) {
    animation-delay: 0.5s;
}

.wave:nth-child(7) {
    animation-delay: 0.6s;
}

.wave:nth-child(8) {
    animation-delay: 0.7s;
}

.wave:nth-child(9) {
    animation-delay: 0.8s;
}

.wave:nth-child(10) {
    animation-delay: 0.9s;
}

@keyframes wave {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

.media-123-parent{
    padding-top: 200px;
    max-width: 1110px;
    width: 100%;
    margin: 0 auto;
}


.media-123-parent h1{
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
    padding-bottom: 30px;
}
.media-container{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, minmax(220px, 1fr));
    padding-top: 20px;
    padding-bottom: 50px;
}



.media-card{
    height: 100%;
    min-height: 200px;
    padding: 20px 15px;
    border-radius: 5px;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
    border: 1px solid #ecedef;
    position: relative;
    cursor: pointer;
    transition: 0.5s;
}
.media-card:hover{
    transform: scale(1.05);
}

.media-card-image{
    width: auto;
    max-width: 40%;
    object-fit: contain;
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 5px;
     

}

.media-description{
    margin-left: 20px;
    padding-top: 15px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: .01em;
    line-height: 160%;
    
}


.arrowicon{
    position: absolute;
    bottom: 15px;
    right: 30px;
}
.media-arrow1{
    position: absolute;
    bottom: 15px;
    right: 30px;
}

@media screen and (max-width: 1184px) {

    .media-container{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .media-card{
        width: 340px;
    }

    .media-123-parent h1{
        font-size: 32px;
    }
   
}

@media screen and (max-width: 600px) {
    .media-123-parent h1{
        font-size: 24px;
    }
}
