
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    width: 100%;
}
.assistance-input-name{
   border-radius: 4px;
   border: 1px solid #E7ECF4;
   background: #fafafc;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    outline: none;
    margin-bottom: 30px;
    font-size: 18px;
    transition: 0.3s;
    color: #000;
}
.assistance-input-name:focus{
    border: 1px solid #800edd;
    box-shadow: 0px 0px 0px 2px rgba(184, 87, 240, 0.5);
}

.assistance-textarea{
    width: 100%;
    border: 1px solid #E7ECF4;
    background: #fafafc;
    font-size: 18px;
    padding-left: 15px;
    padding-top: 10px;
    border-radius: 4px;
    height: 150px;
    outline: none;
    transition: 0.3s;
    color: #000;
}
.assistance-textarea:focus{
    border: 1px solid #800edd;
    box-shadow: 0px 0px 0px 2px rgba(184, 87, 240, 0.5);
}

.cancel-assistance{
    border: none;
    background: #D90808;
    color: #ffffff;
    padding: 5px 15px;
    border-radius: 4px;
    margin-right: 10px;
}
.submit-assistance{
    border: none;
    background:#82d362;
    color: #ffffff;
    padding: 5px 15px;
    border-radius: 4px;
}

.css-hlj6pa-MuiDialogActions-root{
    padding-right:24px!important;
    padding-bottom: 20px!important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    margin: 0!important;
    z-index: 999;
}


.assistance-modal{
    z-index: 999999999999;
}

.assistance-sub-box{
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media screen and (max-width: 600px) {
    

.assistance-modal{
    width: 100%;
    height: 100%;
    /* background-color: #212121; */

}

.assistance-sub-box{
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
    padding-bottom: 20px;
}
.assistance-sub-box h1{
    color: #800edd;
    font-size: 20px;
    padding-top: 20px;
}

.assistance-sub-box img{

    height: 100px;

}
.assistance-input-name{
    
    margin-bottom: 15px;
    /* border: none; */
    
    
}

.assistance-textarea{

   
    height: 100px;
    
    

}



}