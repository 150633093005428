


.parent-loan-explore-card{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 100px;
    width: 100%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;

}

.loan-explore-card{
    background: linear-gradient(180.03deg, #F8F8F8 0.03%, #FFFFFF 0.04%, #FFFFFF 60.38%, rgba(216, 216, 216, 0.28) 138.52%);
    box-shadow: 4px 4px 15px 2px #E3E4E4;
    border-radius: 12px;
    width: 230px;
    height: 210px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
    cursor: pointer;
    transition: 0.5s;
    padding: 0;
}
.loan-explore-card:hover{
    transform: scale(1.05);
}

.loan-card-image{
    margin-top: -60px;
    margin-left: 29%;
    background: #fff;
    box-shadow: 3px 6px 24px rgba(0,0,0,0.1);
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.loan-explore-card img{
    width: 100px;
    
    
    
}

.card-category{
    /* padding-left: 8%; */
    font-size: 24px;
    padding-top: 20px;
    font-weight: 500;
}

.card-interest{
    padding-left: 8%;
    font-size: 15px;
    padding-top: 5px;
    font-weight: 500;

}

 .card-description{
    /* padding-left: 8%; */
    padding:8%;
    font-size: 15px;
    padding-top: 5px;
    font-weight: 450;
    color: #454545;

 }