.slider {
  position: relative;
  display: flex;
  justify-content: center;

  /* background-color: rgba(0, 2, 7, 0.8), url('../images/slider.svg'); */

  /* height: calc(100vh - 47px); */
  margin-top: 130px;
  height: 80vh !important;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/Stocktick.svg");
  background-size: contain;
  /* background-attachment: fixed; */
  scroll-snap-align: start;
  position: relative;
  width: 100%;
  object-fit: contain;
  /* background-position: center top !important; */
}

.mf-mobile {
  display: none;
}

/* @media screen and (max-width:1300px) {
  .slider {
    background-size: 90%;
    height: 55vh;
    justify-content: center;
  }
} */

@media screen and (max-width: 1300px) {
  .slider {
    height: 40vh;
    justify-content: end;
  }

  /* .slider {
    background-image: none;
  } */

  .slider {
    background-size: contain;
    background-position: center;
  }

  /* .slider_btn {
    margin-bottom: 25%;
  } */
}

.bg-black {
  background: rgba(0, 2, 7, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.man {
  flex: 0.2;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
}

.man img {
  object-fit: contain;
  /* width: 100%; */
  height: 100%;
}

.data {
  position: absolute;
  /* margin-left: 30%; */
  left: 20%;
  top: 47%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-end; */
  z-index: 1;
  color: #604fb2;
  font-family: Rubik;
}

.data h1 {
  font-family: "Lato", sans-serif;
  font-size: 36px;
  letter-spacing: 0.3px;
  font-weight: 600;
  -webkit-font-smoothing: auto;
  line-height: 55px;
  margin-bottom: 0;
}

.data h2 {
  padding-top: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  margin-top: 0;
  line-height: 33px;
  color: #7e7c79;
  font-family: "Lato", sans-serif;
}

.slider_btn {
  background: #ff6f5f;
  border-radius: 4px;
  color: #fff5ee;
  align-items: center;
  border: none;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  justify-content: center;
  width: 250px;
  height: 55px;
  cursor: pointer;
  z-index: 999;
  font-family: "Lato", sans-serif;
  margin-top: 30px;
  position: relative;
  /* top: 20%; */
}


/* background: #7258f4;
border-radius: 4px;
width: 200px;
height: 55px;
font-size: 16px;
font-weight: 500;
position: relative; */

.assistance {
  position: fixed;
  top: 140px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #000000;
  z-index: 500;
  cursor: pointer;
  transform: translate(50px);
}

.assistance p {
  writing-mode: vertical-rl;
  font-size: x-large;
  color: #fff;
  transform: rotate(270deg) translate(0);
  text-align: right;
}

.assistance {
  background: #79a366;
  border-radius: 19px 0px 19px 19px;
}

.mb-only-poster {
  display: none;
}

.data img {
  display: none;
}

@media screen and (max-width: 442px) {
  .mb-only-poster {
    width: 100%;
    display: block;
    background-image: url("../images/billy.svg");
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: left bottom; */
    position: absolute;
    bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  .assistance {
    top: 150px;
  }

  .assistance p {
    font-size: 18px;
  }

  /* .slider{
    display: block;
    margin-top: 167px; */
  /* background-image: none; */
  /* } */

  .data {
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
    bottom: 5px;
    top:10%;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .slider {
    height: 40vh !important;
    /* margin-bottom: 40px; */
    margin-top: 100px;
  }

  /* .data img {
    height: 200px;
    display: block;
    margin-bottom: 15px;
  } */

  .data h1 {
    color: #604fb2;
    font-size: 27px;
    font-weight: 600;
    text-align: center;
  }

  .data h2 {
    color: #6c6c61;
    text-align: center;
    font-size: 19px;
    padding-top: 0;
    line-height: 25px;
  }

  .slider_btn {
    background: #7258f4;
    border-radius: 4px;
    width: 200px;
    height: 55px;
    font-size: 16px;
    font-weight: 500;
    position: relative;

    /* right: 2%; */
    /* margin-top: 20px; */
  }
}

@media screen and (max-width: 1000px) {
  .slider {
    flex-direction: column;
  }

  .bg-black {
    top: 500px;
    margin-bottom: 100px;
  }

  .man img {
    width: 100%;
    height: auto;
  }
}

.head-div-1 {
  position: fixed;
  top: 200px;
  right: 0;
  transition: all 0.8s ease-in-out;
  width: 50px;
  height: 160px;
  z-index: 200;
}

.heda123 {
  background: #8ed16f;
  color: #ffffff;
  border: none;
  position: absolute;
  width: 160px;
  height: 50px;
  left: -55px;
  top: 55px;
  transform: rotate(-90deg);
  border-radius: 19px 19px 0 0;
  padding-left: 20px;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  z-index: 125;
}
