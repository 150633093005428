.login-box {
  width: auto;
  display: flex;
  justify-content: center;
  position: relative;
}
.hedaaaa {
  z-index: 99544;
}

.close-modal {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  color: #000;
  position: absolute;
  top: 10px;
  right: 2%;
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
}
.login-left {
  width: 100%;
  margin-bottom: 40px;
  background: #604fb2;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 475px;
}
.login-left h1 {
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.02em;
  padding-top: 40px;
  padding-left: 20px;
}

.login-left p {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */
  padding-left: 20px;
  padding-right: 3%;
  text-align: center;
  letter-spacing: 0.02em;
  margin-top: 180px;
  color: #ffffff;
}

.right-container {
  width: 100%;
  /* margin-bottom: 40px; */
  /* background: rgba(96, 79, 178, 0.25); */
  background: #ffffff;
  position: relative;
  padding: 18px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 475px;
}

/* @media screen and (max-width:780px) {
    .login-box{
        height: 100%;
        flex-direction: column;
    }

    .login-left{
        height: 100%;
        padding: 20px;
        margin-bottom: auto;
    }
    .right-container{
        height: 100%;
        margin-bottom: auto;
    }  
    
} */

.kjjh {
  width: 100%;
}

.invalid-otp {
  padding-left: 5%;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: #ea0606;
  padding-bottom: 10px;
}

.invalid-mbl {
  font-size: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: #ea0606;
  padding-top: 10px;
}

@media screen and (max-width: 600px) {
  .kjjh {
    width: 100%;
  }
}
.inner-container-right {
  width: auto;
  height: 100%;

  background: #ffffff;
  padding: 3%;
}
.inner-container-right h2 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding-top: 20px;
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 20px;
}

.innnnn-pio {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-div-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.input-div-container label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  margin-left: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  /* identical to box height */
  background: transparent;
  color: #404040;
}

.ph-input-box {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  height: 50px;
  padding-left: 20px;
  border: 1px solid #e7ecf4;
  border-radius: 2px;
  background: #fafafc;
  font-size: 15px;
  margin-top: 10px;
  outline: none;
}
.ph-input-box:focus {
  border: 1px solid #800edd;
  box-shadow: 0px 0px 0px 2px rgba(184, 87, 240, 0.5);
}
.input-div-container button {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  background: #800edd;
  border: none;
  color: #fff;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
}

.otp-text {
  font-size: 11px;
  padding-top: 10px;
}

.verify-btn {
  width: 90%;
  margin-left: 5%;
  border-radius: 4px;
  background: #800edd;
  border: none;
  color: #ffffff;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
}

.btnn-divvv button {
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.div-hgtr {
  font-size: 14px;
}
.div-hgtr button {
  color: #230edd;
  font-weight: 500;
}

.copyright {
  width: 100%;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 13px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 480px) {
  .copyright {
    position: relative;
    margin-top: 40px;
    bottom: 20px;
  }
}
.backarrow {
  position: absolute;
  left: 10%;
  top: 45px;
  background: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.otp-confirmation {
  padding-left: 10px;
  font-size: 18px;
  color: #646464;
}

.default-otp {
  width: 90%;
  margin-left: 5%;
  border-radius: 4px;
  border: none;
  color: #6c6b6b;
  height: 20px;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  text-align: center;
}

.otp-body-top {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
}
.btnn-divvv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-left: 5%;
    margin-right: 5%; */
  margin-bottom: 15px;
}
@media screen and (max-width: 600px) {
  .login-box {
    height: 10%;
    flex-direction: column;
    margin-top: 30px;
  }
  .login-left {
    height: auto;
    padding-bottom: 20px;
    margin-bottom: 0px;
    display: none;
  }
  .right-container {
    height: auto;
    padding-bottom: 75px;
    margin-bottom: 0px;
    /* padding-left: 3%;
        padding-right: 3%; */
    padding: 0;
    background: #ffffff;
    box-shadow: 9px 5px 50px 0px #604fb259;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .backarrow {
    position: absolute;
    left: 5%;
    top: 16px;
    background: transparent;
    border: none;
    font-size: 30px;
    cursor: pointer;
  }
  .inner-container-right {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0px;
    padding-bottom: 30px;
    background: #ffffff;
    /* background: #212121; */
    border-radius: 0;
  }
  .inner-container-right h2 {
    margin-top: 60px;
    color: #548cff;
    text-transform: uppercase;
  }
  .login-left p {
    /* font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 26px;
        padding-left: 20px;
        padding-right: 3%;
        text-align: center;
        letter-spacing: 0.02em; */
    margin-top: 0px;
    /* margin-bottom: 0px; */
    color: #ffffff;
  }
  .login-left h1 {
    color: #ffffff;
    font-family: "Roboto";
    font-style: normal;
    text-align: center;
    letter-spacing: 0.02em;
    padding-top: 0px;
    padding-left: 20px;
  }

  .innnnn-pio {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .input-div-container {
    width: 90%;
  }
  .input-div-container label {
    color: #000;
  }
  .otp-text {
    text-align: center;
  }

  .div-hgtr {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
  .copyright {
    bottom: 20px;
    position: relative;
    margin-top: 50px;
    font-size: 15px;
  }

  .inner-container-right h2 {
    font-size: 24px;
    font-weight: 500;
  }

  .bg-whiteee {
    background: #230edd;
  }

  .ph-input-box {
    /* border: 1px solid #2666CF; */
    border: 1px solid #e7ecf4;
    margin-top: 15px;
    border-radius: 2px;
    background: #fafafc;
  }

  .ph-input-box:focus {
    border: 1px solid #2666cf;
    box-shadow: 0px 0px 0px 2px rgba(66, 158, 251, 0.5);
  }

  .input-div-container button {
    background: #2666cf;
    border-radius: 4px;
  }
  .otp-body-top {
    flex-direction: column;
  }

  .otp-confirmation {
    padding-top: 10px;
    color: #aaaaaa;
    font-size: 16px;
  }
  .btnn-divvv {
    flex-direction: column;
  }
  .btnn-divvv button {
    color: #ffffff;
  }

  .otp-text {
    color: #aaaaaa;
    font-size: 12px;
  }

  .backarrow {
    /* color: #ffffff; */
  }

  .div-hgtr {
    color: #aaaaaa;
  }
  .div-hgtr button {
    color: #3098fa;
  }

  .verify-btn {
    background: #2666cf;
  }
}

@media screen and (max-width: 375px) {
  .div-hgtr {
    font-size: 16px;
  }

  .div-hgtr button {
    font-size: 17px;
  }
}
