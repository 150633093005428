.calculator-parent{
  margin-top: 130px;
}


.show-calculator-xz{
  padding-top: 40px;
  padding-bottom: 30px;
  margin-bottom: 50px;
  border: 1px solid #ecedef;
  margin-left: 20px;
  margin-right: 20px;
}
.calculator-heading1{
  
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  padding-top: 50px;
  padding-bottom: 45px;
  color: #101010;
  text-align: center;

}

.pill-nav{
 
  display: flex;
  flex-wrap: wrap;
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  
}
.pill-nav button {
  display: flex;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  width: 200px;
  height: 200px;
  border: none;
  margin: 20px 15px;
  transition: 0.3s ease-in-out;
  color: #fff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.19);
  font-family: 'Lato',sans-serif;
  font-weight: 600;
  position: relative;
  
}

 
.pill-nav button:nth-child(1){
  background: rgb(39, 133, 106);
}


.pill-nav button:nth-child(2){

 background-color: rgb(30, 50, 100);

}


.pill-nav button:nth-child(4){

 background-color: rgb(232, 17, 91);

}

.pill-nav button:nth-child(5){

 background-color: rgb(13, 115, 236);

}

.pill-nav button:nth-child(3){

 background-color: rgb(141, 103, 171);

}

.pill-nav button:nth-child(6){

 background-color: rgb(20, 138, 8);

}

.pill-nav button:nth-child(7){

 background-color: rgb(175, 40, 150);

}

.pill-nav button:nth-child(8){

 background-color: rgb(80, 155, 245);

}



.pill-nav button:nth-child(9){

 background-color: rgb(186, 93, 7);

}


.pill-nav button h3{
    font-size: 22px;
    letter-spacing: .01em;
    line-height: 1.3em;
    font-weight: 600;
    text-align: left;
    position: absolute;
    top: 30px;
    left: 16px;
    
}

.pill-nav button img {
  bottom: 0;
  height: 100px;
  position: absolute;
  right: 0;
  width: 100px;
}
.pill-nav button:hover{
  /* background: #604FB2; */
  
}

.pill-nav .active-pill{
  /* background: #604FB2; */
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.19);
  color: #fff;
  border-radius: 8px;
}

/* Change the color of links on mouse-over */

/* Add a color to the active/current link */
/* .pill-nav button:active {
  background-color: #ddd;
  color: white;
} */


@media screen and (max-width: 600px) {

  .calculator-heading1{
    font-family: 'Poppins',sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pill-nav{
    justify-content: center;
  }
}