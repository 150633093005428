.popup-parent{
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    z-index: 50000;
    display: flex;justify-content: center;
    align-items: center;
}
.popup{
    
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: 475px;
    z-index: 10000;
    border-radius: 10px;
    
    


}

.popup-inner{
    display: flex;

}
.popup-right{
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.popup-right img{
    width: 100%;
    height: 475px;
    margin-top: 0px;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


.close-popup{
    position: absolute;
    top: 20px;
    right: 3%;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;



}

.popup-left{
    width: 100%;
    height: 475px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: relative;
   
}
.popup-left h1{
    text-align: center;
    font-size: 22px;
    line-height: 27px;
    color: rgb(127, 43, 205);

}
.popup-left p{
    color: #454545;
    font-size: 15px;
    padding-left: 3%;
    padding-right: 3%;
}

.logo-img-popup{
    position: absolute;
    bottom: 40px;
    width: 120px;
}

@media screen and (max-width:600px) {

    .popup-inner{
        flex-direction: column;
        border-radius: 0;
    }
    .popup-left{
        border-radius: 0;
        height: 250px;
    }

    .popup-left h1{
        font-size: 17px;
    }
    .popup-left p{

        font-size: 12px;
    }
    .popup-right{
        border-radius: 0;
        height: 350px;
    }
    .popup-right img{
        height: 270px;
        border-radius: 0;
    }
    .logo-img-popup{
        bottom: 20px;
        width: 100px;
    }
    
}