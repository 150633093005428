.modal {
    /* position: absolute;
    top: 0;
    left: 0; */
    /* background-color: rgba(0, 0, 0, 0.8); */
       width: 95%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    margin:auto;
  }
  
  .modal__container {
    background-color: #ffffff;
    
    /* width: 60vw; */
    /* max-height: 70vh; */
    
  }
  .modalTitle{
    display: flex;
    justify-content: space-between;
  }
  
  
  