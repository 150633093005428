.privacy-main {
  margin-top: 127px;
  display: flex;
}

.privacy-sidebar {
  width: 20%;
  background-color: #2e3092 !important;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 127px);
  position: fixed;
}
.privacy-sidebar h1 {
  width: 100%;
  position: absolute;
  text-align: center;
  font-size: 36px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}
.privacy-sidebar p {
  width: 100%;
  position: absolute;
  text-align: center;
  margin-top: 110px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  line-height: 20px;
}

.privacy-policy1 {
  width: 80%;
  margin-left: 20%;
  padding-left: 30px;
  padding-top: 40px;
}
.privacy-policy212 {
  width: 80%;
  margin-left: 20%;
  height: calc(100vh - 127px);
  padding-left: 50px;
  padding-top: 40px;
}

@media screen and (max-width: 990px) {
  .privacy-main {
    display: block;
  }

  .privacy-sidebar {
    width: 100%;
    position: relative;
    height: 200px;
    margin-top: 127px;
  }
  .privacy-policy1 {
    width: 100%;
    margin-left: 0;
  }
}
.hding212 {
  font-size: 24px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}
.ppp212 {
  font-size: 17px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  padding-right: 50px;
}
.ppp2121 {
  font-size: 17px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  padding-right: 50px;
  padding-top: 30px;
}

.hgtr212 {
  padding-bottom: 80px;
}

.hdddder212 {
  font-family: "Lato", sans-serif;
  display: flex;
  align-items: center;
  padding-top: 20px;
  font-size: 18px;
}
.hdddder212 span {
  font-size: 24px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  padding-left: 10px;
}

.content212 {
  padding-left: 24px;
  font-size: 17px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  padding-right: 50px;
  padding-top: 10px;
}

.office1 {
  margin-bottom: 10px;
}

.office1 img {
  height: 24px;
  margin-right: 5px;
}
.office1 h1 {
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.office1 p {
  font-family: "Lato", sans-serif;
  font-size: 17px;
  margin-left: 27px;
}

.office1 a {
  text-decoration: none;
  color: #000;
  font-size: 17px;
}

.aaabb212 {
  width: 80%;
  margin-left: 20%;
  height: calc(100vh - 127px);
  padding-left: 50px;
  padding-top: 40px;
  display: flex;
}

.abcde212 {
  width: 50%;
}

.abcde213 {
  width: 40%;
}
@media screen and (max-width: 986px) {
  .aaabb212 {
    width: 100%;
    margin-left: 0%;
  }
}
@media screen and (max-width: 750px) {
  .aaabb212 {
    width: 100%;
    margin-left: 0%;
    flex-direction: column;
    height: auto;
    padding: 10%;
  }

  

  .abcde212 {
    width: 100%;
  }

  .office1 {
    margin: 0;
    width: 100%;
    display: block !important;
    margin-bottom: 20px;
  }

  .abcde213 {
    width: 100%;
    padding-right: 50px;
    margin-top: 70px;
  }
}
.contact-form212 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: -4px 4px 24px 0 rgb(0 0 0 / 20%);
  padding: 40px 24px;
  border-radius: 15px;
}

.contact-form212 input {
  border: 1px solid #ecedef;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  width: 100%;
  font-size: 17px;
  font-family: "Lato", sans-serif;
  border-radius: 3px;
}

.contact-form212 textarea {
  border: 1px solid #ecedef;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  width: 100%;
  font-size: 17px;
  font-family: "Lato", sans-serif;
  border-radius: 3px;
}

.send-msg {
  width: 100%;
  margin-top: 15px;
  padding: 15px;
  border: none;
  outline: none;
  background-color: #2e3092;
  color: #fff;
  font-size: 17px;
  font-family: "Lato", sans-serif;
  border-radius: 4px;
}

.div-aboutt {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-box {
  width: 90%;
  background-color: #2e3092;
  height: 520px;
  margin-top: 110px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  /* padding-left: 15%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-box h2 {
  font-family: "Lato", sans-serif;
}
.about-box h1 {
  font-family: "Lato", sans-serif;
}
.about-box p {
  font-family: "Lato", sans-serif;
  font-size: 17px;
  /* padding-right: 30%;
  padding-left: 5%;
  /* padding: 10%; */
  margin: 0 auto;
  width: 60%;
}
.heikaaaaa {
  margin-top: 50px;
  width: 100%;
}
.heikaaaaa h1 {
  font-size: 36px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  text-align: center;
}
.heikaaaaa p {
  font-size: 17px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  text-align: center;
}

.jkhtyr {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: space-around;
}

.slab12 {
  /* width: 23%; */
  margin-left: 1%;
  margin-right: 1%;

  display: flex;
  justify-content: center;
}
.slab12 img {
  width: 50px;
  height: 50px;
}

.kljyu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
}
.kljyu h1 {
  font-size: 20px;
  color: #2e3092;
}
.kljyu p {
  font-size: 14px;
  margin-top: -10px;
}

@media screen and (max-width: 830px) {
  .jkhtyr {
    /* justify-content: center;
    align-items: center; */
    display: block;
  }
  .slab12 {
    width: 100%;

    /* margin-left: 0;
        margin-right: 0; */
  }
}
@media screen and (max-width: 800px) {
  .about-box {
    width: 100%;
    background-color: #2e3092;
    height: 520px;
    margin-top: 130px;
    margin-bottom: 10px;

    color: #fff;
    padding: 10%;
  }

  .about-box p {
    margin-top: 15px;
    font-family: "Lato", sans-serif;
    font-size: 17px;
    /* padding-right: 60%; */
    padding: 0;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .slab12 {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kljyu h1 {
    font-size: 18px;
  }

  .kljyu p {
    font-size: 15px;
  }

  .slab12 img {
    width: 30px;
    height: 30px;
  }
}
