:root {
  background-color: #e5e5e5;
}

.pagination1 {
 
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
   
}

.pagination1 h1{
  text-align: center;
  padding-bottom: 50px;
  font-size: 38px;
}

.blogs-rows {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3,1fr);
  min-height: 70vh;
}

.blog-box {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 97%;
  border-radius: 22px;
  background-color: #a3abce;
  cursor: pointer;
  animation-name: blog-box;
  animation-duration: 3s;
  position: relative;
}

.blog-link {
  text-decoration: none;
}

.blog-box1 {
  width: 100%;
  height: fit-content;
  background-color: #a5add3;
  border-radius: 8px;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
}

.blog-box1 :hover {
  /* transform: translateY(-5px); */
}

/* .blog-link :hover {
  transform: scale(1.01);
} */

.blog-box-upper {
  position: relative;
  height: 300px;
  width: 100%;
}

.blog-box-lower {
  width: 100%;
}

.info-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  color: #ffffff;
  background-color: #ff4311;
}

.info-bar-category {
  background-color: #00519c;
  width: 60%;
  height: 100%;
  padding-left: 10px;
  border-radius: 0px 15px 15px 0px;
}

.info-bar-date {
  background-color: #ff4311;
  height: 100%;
  width: 40%;
  padding-left: 10px;
}

.image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
  filter: brightness(40%);
}


.blog-box:hover {
  transform: scale(1.02);
  transition: 0.2s;
  transition-timing-function: ease-in;
}

@keyframes blog-box {
  0% {
    opacity: 0%;
    transform: translateY(100px);
  }
  100% {
    opacity: 100%;
  }
}

.bookmark-icon {
  height: 100%;
  width: auto;
  top: 0px;
  z-index: 11;
}

.bookmark-icon:hover {
  transform: translateY(-15px);
  transition: 0.5s;
}

.bookmark-icon:active {
  transform: translateY(4px);
  transition: 0.5s;
}

.bookmark-icon-inactive {
  display: none;
}

.heading {
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
  top: 0;
}

#username {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  cursor: pointer;
  color: #ffffff;
}

#username:hover {
  color: #ffbd06;
  transition: 0.5s;
}

#date {
  margin-top: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #828282;
}

#likes-icon {
  color: #ff0000;
  margin-left: 6%;
  font-size: 30px;
  position: relative;
  cursor: pointer;
}

#likes-icon:hover {
  color: rgb(184, 6, 6);
  transition: 0.5s;
  transform: scale(1.2);
}

#likes-icon:active {
  transform: translateY(4px);
  transition: 0.2s;
}

#likes {
  position: relative;
  z-index: 1;
  margin-left: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 28px;
  color: #ffffff;
}

.info {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 60%;
  top: 0;
  color: #ffffff;
}

.author-pic {
  border-radius: 30px;
  height: 54px;
  width: 54px;
  object-fit: cover;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.5s;
}

.author-pic:hover {
  transform: scale(1.2);
  
}

.author-pic:active {
  transform: translateY(4px);
  transition: 0.2s;
}

.body {
  display: flex;
  flex-direction: column;
  margin-left: 8%;
  text-decoration: none;
  width: 91%;
}

.social-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  
}

.social-panel img {
  height: 30px;
  margin-right: 15px;
}

.content {
  /* margin-top: 10px;
  margin-bottom: 10px; */
  /* width: 100%; */
  padding: 10px;
}

.content h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.content p {
  margin-top: 10px;
  font-family: Roboto;
  font-weight: normal;
  font-size: 19px;
  line-height: 22px;
  color: #00519C;
  justify-content: center;
}

/* .image:hover {
  transform: scale(1.02);
  transition: 0.5s;
  transition-timing-function: ease-out;
} */

.footing {
  display: flex;
  position: absolute;
  flex-direction: row;
  bottom: 0px;
  padding-left: 2%;
}

.footing a {
  margin-top: 16px;
  margin-right: 60px;
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  
}

.footing a img{
  margin-right: 7px;
}

.footing a:hover {
  transform: translateY(-4px);
  transition: 0.5s;
}

#load {
  position: relative;
  margin-top: 0px;
  border: 3px solid #00519c;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 22px;
  padding-right: 22px;
  font-family: 'Poppins',sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 28px;
  color: #000000;
  cursor: pointer;
}

#load:hover {
  background-color: #00519c;
  color: #ffffff;
  border: 3px solid #000000;
  transition: 0.5s;
}

#load:active {
  transform: translateY(4px);
  transition: 0.5s;
}

#load-ani {
  left: 50%;
  height: 100vh;
  align-items: center;
  justify-items: center;
}

#load-up {
  top: 0;
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 40px;
  white-space: nowrap;
}

#load-inactive {
  display: none;
}

#nav {
  margin-bottom: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

#bmi {
  position: absolute;
  margin-left: 92%;
  z-index: 9;
}

#drop-down {
  position: relative;
  z-index: 3;
  margin-top: 50px;
  margin-left: 20px;
  width: fit-content;
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  cursor: pointer;
}

#down-arrow {
  position: relative;
  left: 8px;
  scale: 0.7;
}

#subs {
  z-index: 1;
}

.btn {
  padding: 0.5rem 3rem;
  background-color: #ffae00;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.25rem;
  border: none;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.25s;
}

#category-head {
  margin-bottom: 6px;
  font-weight: bolder;
}

#link {
  text-decoration: none;
  line-height: 40px;
}

@media screen and (max-width: 1300px) {
  /* #sort-direction {
    left: 95%;
  } */
  .icon {
    height: 35px;
  }
  .body {
    margin-left: 6%;
  }
}

#load-inactive {
  left: 200px;
}

@media screen and (max-width: 800px) {
  .image {
    max-width: 630px;
  }
  #access-bar {
    display: flex;
    flex-direction: row;
    height: 60px;
    border: 1px solid #000000;
    align-items: center;
    justify-content: flex-end;
  }
  #side {
    display: none;
  }
  #whole {
    justify-content: center;
  }
  #core {
    width: 95%;
  }
  #access-drop-container {
    justify-content: center;
  }
  #load {
    justify-self: center;
  }
  #likes-icon {
    font-size: 24px;
    right: 10%;
  }
  #likes {
    font-size: 13px;
    right: 10%;
  }
  .bookmark-icon {
    position: relative;
    width: 28px;
    height: 35px;
  }
  #bmi {
    margin-left: 88%;
  }
  .footing a {
    margin: 0;
  }
  #core {
    margin-top: 100px;
  }
}

@media screen and (max-width: 420px) {
  .bookmark-icon {
    left: 300%;
    height: 38px;
  }
  #bmi {
    margin-left: 60%;
  }
  #likes {
    font-size: 12px;
  }
  .footing {
    width: 100%;
    justify-content: space-evenly;
  }
  .footing a {
    font-size: 13px;
    margin: 2px;
  }
  .username {
    font-size: 15px;
  }
  .body {
    margin: 0;
    align-self: center;
  }
}

@media screen and (max-width: 1041px) {
  .blogs-rows {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 700px) {
  .blogs-rows {
    grid-template-columns: 48% 48%;
  }
}



.blogcard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 340px;
  margin-bottom : 20px;
  background: #ffffff;
  border: 1px solid #ecedef;
  border-radius: 8px;
  /* box-shadow: 0px 3px 3px 3px #e2d2d2; */
}

.blogcard:hover {
  transform: translateY(4px);
  transition: 1s;
}

.blogcard img {
  
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 200px;
  object-fit: cover;

  
 
}

.heading-box-blog{
    
  padding: 15px 15px 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 80px;
}

.blogcard-heading {
  font-family: "Lato", sans-serif;
  color: #44475b;
  text-align: center;
  font-size: 1.125rem;
  letter-spacing: .01em;
  line-height: 160%;
  font-weight: 600;
  z-index: 1;
  word-wrap: break-word;
  
}

.blogcard-date {
  position: absolute;
  top: 10px;
  right: 5px;
  color: #e5e5e5;
  background-color: #ff4311;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 5px 10px 5px 10px;
  z-index: 1;
}

@media screen and (max-width: 600px) {

  .pagination1{
    padding-top: 10px;
  }
  .pagination1 h1{
    font-size: 27px;
  }

  .blogs-rows{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .blogcard{
    box-shadow: none;
    width: 320px;
    
  }
  .blogcard-heading{
    font-size: 16px;
    font-weight: 500;
    bottom: 28px;
  }
  .blogcard-date{
     right: 3%;
  }
}