.headline {
  margin: auto;
  background-color: #604fb2;
  display: flex;
  color: #ffffff;
  height: 47px;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
}
.text {
  font-size: large;
  text-align: center;
}


@media screen and (max-width:600px) {
  
    .headline{
      height: 60px;
      padding-top: 10px;
      padding-bottom: 10px;
      /* background: #686868; */

    }
    .text{
      font-size: 14px;
      padding-left: 5%;
      padding-right: 5%;
      color: #fff !important;
    }
}