.insurance-poster {
  width: 100%;
  height: 80vh;
  margin-top: 0px;


  display: flex;
  position: relative;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

.insurance-poster-left {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  
  
}

.insurance-poster-left h1 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-size: 36px;
  font-weight: 500;
}

.insurance-poster-left h1 span{
   color:#52CCC3;
   font-weight: 600;
}
.insurance-poster-left p {
  /* margin: 5px; */
  margin-top: 20px;
  
  color: #454545;
  font-size: 20px;
  padding-right: 10%;
  
}


.apply-now-btn12 {
  
  background: #52ccc3;
  border: none;
  /* padding: 15px 29px; */
  color: #fff;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  display: inline-block;
  margin-top: 20px;
  width: 280px;
  padding-top: 15px;
  padding-bottom: 15px;
  
}

.apply-now-btn123 {
  
  background: #52ccc3;
  border: none;
  /* padding: 15px 29px; */
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  border-radius: 4px;
  display: inline-block;
  margin-top: 20px;
  width: 200px;
  height: 55px;
  
}

.insurance-poster-right{
  
  display: flex;
  justify-content: center;
  width: 45%;
  
}
.insurance-poster-right img{
  margin-top: 130px;
  width: 90%;
 
}

.insurance-mobile{
  display: none;
}

#discover_mf{
  background-color: rgba(96, 79, 178, 0.2);
  padding: 10px;
}
.borrow-heading{
  font-size: 32px;
   
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width : 848px) {
  .insurance-poster{
    /* height: auto; */
    padding-bottom: 20px;
  }
  
}
@media screen and (max-width : 700px) {

  .insurance-poster{
   
    flex-direction: column;
    height: auto;
    padding-bottom: 20px;
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
  .insurance-poster-left{
    width: 100%;
    padding-top: 160px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .insurance-poster-left h1{
    color: #000;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    padding-top: 20px;
    padding-left: 3%;
    padding-right: 3%;
  }
  .insurance-poster-left p{
    text-align: center;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    
    color: #454545;
  }
  .insurance-poster-right{
    display: none;
  }

  .insurance-mobile{
     width: 100%;
     display: flex;
     justify-content: center;

  }
  .insurance-mobile img{
    height: 300px;
  }

  .apply-now-btn123{
    width: auto;
    height: auto;
    font-size: 14px;
    background: #52CCC3;
    border-radius: 25px;
    
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #fff;
    font-weight: 600;
  }
  .borrow-heading{
    font-size: 24px;
    padding-top: 20px;
    padding-bottom: 15px;
  }
}






@media screen and (max-width:500px) {

   
  .insurance-poster{
   
    flex-direction: column;
    height: auto;
    padding-bottom: 20px;
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
  .insurance-poster-left{
    width: 100%;
    padding-top: 160px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .insurance-poster-left h1{
    color: #000;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    padding-top: 20px;
    padding-left: 3%;
    padding-right: 3%;
  }
  .insurance-poster-left p{
    text-align: center;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    
    color: #454545;
  }
  .insurance-poster-right{
    display: none;
  }

  .insurance-mobile{
     width: 100%;
     display: flex;
     justify-content: center;

  }
  .insurance-mobile img{
    height: 200px;
  }

  .apply-now-btn123{
    width: auto;
    height: auto;
    font-size: 14px;
    background: #52CCC3;
    border-radius: 25px;
    
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #fff;
    font-weight: 600;
  }
  .borrow-heading{
    font-size: 24px;
    padding-top: 20px;
    padding-bottom: 15px;
  }

  
}










@media screen and (max-width:600px) {
  #discover_mf{
    padding: 5px;
    margin: 0;
    width: 100vw;
    height: fit-content;
  }
}