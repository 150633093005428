.abcdew{
    display: flex;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 100px;
}
.customer-edit-profile{
    width: 90%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-top: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    
}




.reactform {
    background: transparent;
    /* margin-left: 30px; */
    /* background-color: aqua; */
    width: 100%;
    font-family: 'Lato',sans-serif;
    /* margin-top: 30px; */
}
.reactform h1{
    font-size: 27px;
}

.label-cust-edit-profile {
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #000000;    
    padding: 0;
    margin-left: 0;
}


.icon-demo{
    width: 24px;
    height: 24px;
}

.input-entry {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    max-width: 700px;
    width: 100%;
    margin-top: 20px;
    
}

.input-text {
    border: 1px solid #ecedef;
    border-radius: 3px;
    text-transform: capitalize;
    height: 30px;
    padding-left: 15px;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
}

.input-text-email{
    height: 30px;
    border: 1px solid #ecedef;
    border-radius: 3px;
    background-color: #FFFFFF;
    font-size: 20px;
    font-weight: 400;
    padding: 8px;
    color: #000000;
    width: 100%;
    outline: none;

}

.input-text-email:focus{
    border: 2px solid #9b12e4;
}

.input-number {
    margin-left: 10px;
}

.input-container-nodi{
    width: 100%;
    /* background-color: #12518B; */
}

.input-textarea {
    width: 100%;
    border: 1px solid #ecedef;
    height: fit-content;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    padding-bottom: 20px;
    border-radius: 3px;
    font-size: 20px;
    text-transform: capitalize;
    outline: none;
}

.input-textarea::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.mobnumber-input-box {
    display: flex;
    flex-direction: row;
}

.dial-code-drop {
    border: 2px solid #ecedef;
    border-radius: 3px;
    background: transparent;
    cursor: pointer;
    font-size: 16px;
    padding: 0px;
    height: 30px;
}

.blood-group-drop {
    border: 1px solid #ecedef;
    border-radius: 3px;
    background-color: #FFFFFF;
    /* height: 40px; */
    outline: none;
    height: 30px;
    padding: 0px;
    font-size: 20px;
}
.blood-group-drop:focus{
    border: 1px solid #198dfa;
}


.dial-code-drop:focus{
    border: 1px solid #198dfa;
}

.see-password-btn {
    position: absolute;
    right: 12px;
    top: 50px;
    cursor: pointer;
}

.change-button {
    position: absolute;
    top: 5px;
    right: 2px;
    color: #fff;
    background-color: #5a0bba;
    text-decoration: none;
    border-radius: 3px;
    padding: 4px 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;
}

.form-btn-edit-profile{
    /* background-color: #00519C;
    color: #FFFFFF;
    font-size: 18px;
    padding: 12px 25px;
    margin-top: 20px;
    border-radius: 0px; */

    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #fff; 
    text-align: center; 
    text-decoration: none; 
    vertical-align: middle; 
    cursor: pointer; 
    -webkit-user-select: none; 
    user-select: none; 
    background-color: #5a0bba; 
    border: 1px solid transparent; 
    padding: .375rem .75rem; 
    font-size: 1rem; 
    border-radius: .25rem; 
    margin-top: 20px;
}

.second-div-flexx{
    width: 95%;
    margin-right: 2.5%;
    margin-top: 50px;
    padding-top: 30px;
    font-family: 'Lato',sans-serif;

}
.second-div-flexx h1{
    font-size: 27px;
}



@media screen and (max-width:800px) {

    .abcdew{
        flex-direction: column;
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .customer-edit-profile{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        background-color: #FFFFFF;
        padding-top: 10px;
    }
    .reactform{
        /* width: 90%;
        margin-left: 5%;
        margin-right: 5%; */
    }

    .input-text{
        background-color: #FFFFFF;
        font-size: 18px;
        font-weight: 400;
        border: 1px solid #CFD3DC;
    }

    .input-text-email{
        font-size: 18px;
        height: 30px;
        border: 1px solid #CFD3DC;
        font-weight: 400;
        outline: none;
    }
   

    .dial-code-drop{
        background-color: #FFFFFF;
        font-size: 18px;
    }
    
    .label-cust-edit-profile{
        font-size: 16px;
        font-weight: 400;
    }

    .change-button{
        font-size: 16px;
        top: 0;
    }

    .input-textarea{
        border: 1px solid #CFD3DC;
        font-size: 18px;
    }
    .form-btn-edit-profile{
        padding: 4px 15px;
        border-radius: 0px;
        float: right;
        margin-right: 10%;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .dial-code-drop{
        border: 1px solid #CFD3DC;
    }
    .see-password-btn{
        display: none;
    }
}

