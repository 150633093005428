.parent-key{
    width: 100%;
    margin-top: 140px;
}


.parent-key-card{
    margin-bottom: 10px;
    max-width: 1800px;
    font-family: 'Lato',sans-serif;
    width: 95%;
    margin-left: 2.5%;
    margin: 0 auto;
    
}

.parent-key-card h1{
    font-family: 'Lato',sans-serif;
    font-size: 27px;
    padding-bottom: 15px;
    width: 100%;
    position: relative;
}
.add-card-btn{
    border: none;
    background: transparent;
    color: #5EC775;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.331915px;
    text-transform: uppercase;
    position: absolute;
    right: 0;
    bottom: 20px;
}

.parent-card-true{
    max-width: 1800px;
    font-family: 'Lato',sans-serif;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.parent-card-true1{
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    width: 48%;
    border-radius: 7px;
    background: #FFF;
    box-shadow: 3px 4px 33px 3px rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 5px;
    position: relative;
}

.delete-card-btn{
    position: absolute;
    right: 15px;
    top: 10px;
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.331915px;
    color: #EA0606;
    text-transform: uppercase;

}
.delete-card-btn img{
    margin-right: 5px;
}


.input-entry1{
    position: relative;
    margin-top: 15px;
}

.change-button1{
    position: absolute;
    right: 40px;
    top: 5px;
}
.change-button1 button{
    border: none;
    background: transparent;
    text-transform: uppercase;
    color: #252F40;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.331915px;


}
.change-button1 button img{
    margin-right: 5px;
}

.input-postfix {
    font-size: 20px;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 0px 3px 3px 0px;
    padding: 2px 3px;
}

.div-flex {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.div-flex-one {
    margin-right: 10px;
    width: 48%;
}

.div-flex-two {
    width: 48%;
}

.div-form-button {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #5a0bba;
    border: 1px solid transparent;
    font-size: 1rem;
    border-radius: .25rem;
    margin: 10px auto 0px;
}

.input-percent {
    width: calc(100% - 26px);
}
.input-year {
    width: calc(100% - 35px);
}

@media screen and (max-width:800px) {
    .div-flex-one {
        margin-right: 10px;
        width: 98%;
    }

    .div-flex-two {
        width: 98%;
    }
    
    .parent-card-true1 {
        width: 99%;
    }
}