:root {
  --primary: #fff;
  --secondary: #00519c;
  --dark: #000000;
}

.btn1 {
  /* padding: 8px 20px; */
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn-mobile {
  text-decoration: none;
}

.btn--primary {
  background-color:#4222df!important;
  color: var(--primary);
  font-weight: 500;
  padding: 8px 20px !important;
  margin-right: 10px;
  border-radius: 4px;
  /* border-bottom: 2px solid #ffffff!important; */
  border: none;
  font-size: 18px !important;
  margin-top: 7px;
  outline: none !important;
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
}
.btn--primary:hover{
  color: var(--primary);
}

.btn--line {
  background-color: #604fb2 !important;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  color: #ffffff !important;
  padding: 8px 20px !important;
  margin-right: 10px;
  transition: all 0.3s ease-out;
  border-radius: 4px !important;
  margin-top: 7px;
  outline: none !important;
  font-size: 18px !important;
}

.btn--line:hover {
  background-color: #005fb8 !important;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
  background-color: #fff;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
  background-color: #fff;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
 
  /* border: 10px solid black; */
  transition: 250ms;
}
