.education-poster{
    width: 100%;
    /* height: calc(100vh - 150px); */
    height: 60vh;
    margin-top: 130px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../images/education-shadow.svg);
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;

}


.poster-left{

    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
}

.poster-left h1{
    font-size: 38px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    font-weight: 600;
}

.poster-left p{
    color: #454545;
    font-size: 20px;
    padding-top: 10px;
    padding-right: 20%;
}

.poster-image{
    
   width: 90%;
   
   
}

.poster-image img{
     
     width: 90%;
}

.carousel-control-prev-icon{
    background-color: #000000;
    width: 60px;
    height: 60px;
    background-size: 50px;
    border-radius: 50%;
}

.carousel-control-next-icon{
    background-color: #000000;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: 50px;
    position: absolute;
    
}

.education-mob-img{
    display: none;
}

.d-block{
    width: 100%;
    object-fit: cover;
}
@media screen and (max-width:1100px) {
    .poster-image{
        margin-top: 80px;
    }
    
}
@media screen and (max-width: 750px) {
    .education-poster{
        flex-direction: column;
        height: auto;
        background-image: none;
        padding-left: 0;
        padding-right: 0;
        
    }
    .poster-image{
        display: none;
    }
    .education-mob-img{
        margin-top: 30px;
        display: flex;
        justify-content: center;

    }
    .education-mob-img{
        height: 200px;
    }
    .poster-left{
        width: 100%;
        padding-left: 0;
        padding-top: 40px;
    }
    .poster-left h1{
          font-family: 'Poppins',sans-serif;
          text-align: center;
          font-size: 20px;
          font-weight: 600;
          padding-bottom: 15px;
    }
    .poster-left p{
       
        text-align: center;
        padding-right: 0;
        font-size: 16px;
        color: #454545;
        padding-left: 4%;
        padding-right: 4%;
        font-weight: 400;
    }
  }
















  /* Learn More */


.learn-parent{
    max-width: 1300px;
    width: 90%;
    margin: 20px auto;
    margin-top: 50px;
}
.learn-parent h1{
    text-align: center;
    padding-bottom: 30px;

}
  
.learnnn-more{
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
}

.stocktick-card1{
    margin-bottom: 15px;
}

@media screen and (min-width: 630px) {

    .learn-parent{
        padding-left: 5%;
        padding-right: 5%;
    }

    .learnnn-more{
        grid-template-columns: repeat(2, 1fr);
        }

    
}

@media screen and (min-width: 1024px) {

    .learn-parent{
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .learnnn-more{
    grid-template-columns: repeat(3, 1fr);
    }
    
}


@media screen and (min-width: 1324px) {

    .learn-parent{
        width: 100%;
    }

    .learnnn-more{
    grid-template-columns: repeat(4, 1fr);
    }
    
}