.tick-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.tick-list-heading {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 30px;
}

.tick-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.tick-list-item img {
  width: 16px;
  height: 11px;
  margin-right: 20px;
}

.tick-list-item div {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.tick-list-heading h4{
  font-size: 30px;
}

.design-fundbazar{
  font-size: 36px;
  color: #36455D;
}

.fundbazar-btn{
  background: #ffffff;
  outline: none;
  border: none;
  border-radius: 10px;
  box-shadow:
    inset -4px -4px 10px 5px rgba(96, 79, 178, 0.2), inset 4px 4px 10px 5px rgba(96, 79, 178, 0.2);
  width: 140px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}

@media screen and (max-width: 500px) {
  .tick-list-heading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .tick-list-item div {
    font-size: 15px;
    /* color: #ffffff; */
    font-family: 'Poppins',sans-serif;
  }
  .design-fundbazar{
    font-size: 30px;
    /* color: #ffffff; */
    padding-bottom: 30px;
  }

  .tick-list-heading h4{
    font-size: 20px;
    /* color: #ffffff; */
    
  }

  .fundbazar-btn{
      margin-bottom: 30px;
      margin-top: 0;
  }
}