.independence {
  /* margin-top: 10px; */
  /* background-image: url(../images/home-banner1.svg); */
  width: 100%;
  /* height: 100vh; */
  /* background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */
  /* position: fixed; */
  /* background-attachment: fixed; */
  /* scroll-snap-align: start; */
  /* height: 20% !important; */
  height: 750px;
}

@media screen and (max-width: 1300px) {
  .independence {
    height: 100vh;
    background-size: 100%;
    /* background-position: left; */
    background-position: center;
  }
}

@media screen and (max-width: 600px) {
  .independence {
    height: 30vh;
    background-size: 100%;
    /* background-position: left; */
    background-position: center;
  }
}

.ind_info {
  width: 100%;
  font-family: "Ubuntu", sans-serif;
  /* color: #000000; */
  z-index: 1;
  margin-top: 5px;
  /* height: 100vh; */
  height: 80% !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
}

.ind_info h1 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  padding-left: 10%;
  line-height: 55px;
  letter-spacing: 0.3px;
  padding-top: 80px;
  margin-top: 0;
  color: #604fb2;
  padding-bottom: 0;
}

.ind_info p {
  font-family: "Rubik", sans-serif;
  color: #7e7c79;
  padding-left: 10%;
  font-weight: 300;
  font-size: 24px;
  line-height: 43px;
}

@media screen and (max-width: 760px) {
  .ind_info h1 {
    padding-left: 5%;
  }

  .ind_info p {
    padding-left: 5%;
  }
}

@media screen and (max-width: 650px) {
  .ind_info h1 {
    padding-right: 10%;
  }
}

.floting-box {
  width: 45%;
  height: 45%;
  position: absolute;
  top: 60px;
  right: 5%;
  background-image: url(../images/home-banner1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 1200px) {
  .floting-box {
    bottom: 0;
    top: auto;
  }
}

@media screen and (max-width: 600px) {
  .ind_info {
    height: 100%;
    width: 100%;
  }

  .floting-box {
    width: 350px;
    height: 350px;
    bottom: 20px;
    top: auto;
  }

  .ind_info h1 {
    padding-left: 5%;
    font-size: 27px;
    line-height: 30px;
    font-weight: 600;
    font-family: "Lato", sans-serif;
  }

  .ind_info p {
    padding-left: 5%;
    font-size: 19px;
    line-height: 25px;
    color: #6c6c61;
    font-weight: 500;
  }
}

@media screen and (max-width: 1000px) {
  .ind_img {
    z-index: -1;
  }
}

@media screen and (max-width: 1100px) {
  .ind_img {
    height: 50px;
    width: 50px;
    display: none;
  }
}
