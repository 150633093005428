.profile-page{
    background: #fff;
    padding:0;
    margin:0;
    margin-top: 127px;
    position: relative;
    padding-bottom: 60px;
}


.profile-page-button{
    
   
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:90%;
    margin-left: 5%;
    /* border:1px solid black; */
    margin:30px auto 0 auto;
}

.profile-page-mainlogo{
    width: 100px;
    z-index: 54414;
   
}
.btn--divvv{

}

.profile-page-button1{
  


    background-color: #FF7666;
     
     text-decoration: none;
     border: none;
   
      width:120px;
     color: #ffffff ;
     padding: 10px 20px;
  
 margin-right: 25px;
     border-radius: 10px ;
  
     outline: none ;
     font-size: 18px ;
     cursor: pointer;
 }

 .profile-page-button2{
  


    background-color: #604FB2;
     
     text-decoration: none;
     border: none;
   
    width:120px;
     color: #ffffff ;
     padding: 10px 20px ;
  
 
     border-radius: 10px ;
  
     outline: none ;
     font-size: 18px ;
     cursor: pointer;
 }
 

.profile-page-img1{
    position: absolute;
    top:0;
    left:0;
}


.profile-page-img2{
    position: absolute;
    right:0;
    bottom:0;
}

.profile-container{
   /* border:1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    max-width: 450px;
    width: 100%;
    height:auto;
    padding-top: 20px;
    
}



.profile-container-logo{
    margin: auto;
}

.profile-container-logo > img{
   border-radius: 100%;
   width:176px;
   height:176px;
   margin: auto;
   object-fit: cover;
   /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
   border: 8px solid #EEEEEE;
}

.profile-container-logo-edit{
    border-radius: 100%;
    background-color: white;
    border:1px solid black;
    width:27px;
    height:27px;
    position: relative;
    top: -42px;
    left: 118px;
   
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-container-credeb{
    display: flex;
    justify-content: space-between;
    width:100%;
    
}

.profile-container-credeb1{
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FF7666;
    width:40%;
    padding:4px 4px;
    border-radius: 5px;
}

.profile-container-credeb2{
    background-color: #604FB2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width:40%;
    padding:4px 4px;
    border-radius: 5px;
}

.profile-container-user{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

}

.profile-container-user-info-input{
    width:100%;
    height: 30px;
    border:none;
    color:#000;
    outline: none;
    font-size: 18px;
    padding: 3px 0 3px 5px;
    background-color: transparent;

  
}

.profile-container-user-info{
    border : 1px solid #ecedef;
    border-radius: 4px;
    outline: none;
    margin-top: 10px;
    margin-bottom: 8px;
    padding: 8px 0;
    width: 100%;
    display: flex;
    padding-left: 5px;
    justify-content: space-between;
}

.age-gender-flex{
    display: flex;flex-direction: column;
}

.age-gender-flex input{
    
    border: 1px solid #ecedef;
    padding: 10px 0;
    padding-left: 12px;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #000;
    outline: none;
   
}
.select-gender{
    width: 100%;
    outline: none;
    background-color: transparent;
}

.profile-container-button{
    text-align: center;
    margin-top: 3px;
}

.profile-container-button1{
  


   background-color: #263238;
    
    text-decoration: none;
    border: none;
  
   width:120px;
    color: #ffffff !important;
    padding: 10px 20px !important;
 

    border-radius: 4px !important;
 
    outline: none !important;
    font-size: 18px !important;
    cursor: pointer;
}





@media screen and (max-width: 600px) {

    .profile-page-img1{
        display: none;
        
    }
    .profile-page-img2{
        display: none;
    }

    .profile-container-user{
        width: 90%;
    }
    
}

@media screen and (max-width: 400px) {

    .profile-page-mainlogo{
        width: 80px;
    }
    .profile-page-button1{
        width: auto;
        padding: 8px 20px;
        border-radius: 32px;
    }
    .profile-page-button2{
        width: auto;
        padding: 8px 20px;
        border-radius: 30px;
    }
    .profile-container-credeb1{

        width: auto;
        padding-right: 20px;
        padding-left: 20px;

    }
    .profile-container-credeb2{
            width: auto;
            padding-right: 20px;
            padding-left: 20px;
    }
    .profile-container-credeb{
        width: 90%;
    }
}

.test1 {      
    width: auto;      
    background-color: lightblue;       
    border: 2px solid black;      
    padding: 10px;      
    word-wrap: break-word;      
    font-size: 20px;
    margin-top: 10px;
    text-align: center;
}  