.risk-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30000;
}

.riskfactor-modal-box {
  max-width: 900px;
  width: 100%;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 50000;
  position: relative;
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 2%;
  padding-right: 2%;
}

@media screen and (max-width: 500px) {
  .riskfactor-modal-box {
    padding-bottom: 60px;
  }
}

.input-container {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.input-container label {
  margin-right: 0px;
  padding-bottom: 8px;
  font-size: 19px;
}

.input-container input {
  padding-left: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #ecedef;
  outline: none;
  border-radius: 3px;
  font-size: 17px;
}

.input-container input:focus {
  border: 1px solid #800edd;
  box-shadow: 0px 0px 0px 2px rgba(184, 87, 240, 0.5);
}

.modal-heading21 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 50px;
}

.success-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-img img {
  width: 120px;
}
.modal-heading21-51 {
  font-size: 17px;
  text-align: center;
  margin-top: 20px;
}

.submit-webinar-form {
  background-color: #2f2e41;
  color: #ffc727;
  border: none;
  float: right;
  padding: 10px 25px;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .riskfactor-modal-box {
    border-radius: 0;
    padding-left: 0;
  }

  .text {
    font-weight: bold;
    color: black;
    font-size: 15px !important;
  }
}
