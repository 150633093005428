@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css");

.navbar {
  background-color: white;
  /* box-shadow: 0px 8px 20px 0px #00000008; */
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  top: 47px;
  z-index: 1000;
  position: fixed !important;
  width: 100%;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid #ecedef;
}

.topright-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.navbar.active {
  /* background-color: #F4F4F4; */
  background-color: white;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 10%);
  /* margin-top: 0;
  top: 0; */
  transition: 0.1s;
}
.navbar-container {
  display: flex;
  padding-left: 44px;
  padding-right: 44px;
  /* justify-content: space-evenly; */
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 64px;
  /* background-color: #000; */
  max-width: 1600px;
  top: 0px;
  position: sticky;
  /* margin-top: -8px; */
}

.navbar-logo {
  justify-self: start;
  /* justify-content: start; */
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer;
  text-decoration: none;
  /* background-color: aqua; */
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  line-height: 37.5px;
  transition: 0.5s;
}

.navbar-logo img {
  width: 150px;
  height: 65px;
  object-fit: contain;
}

.nav-menu {
  display: flex;
  justify-content: flex-start;
  grid-gap: 0px;
  list-style: none;
  text-align: center;
  /* width: 50vw; */
  margin-top: 10px;
  justify-self: flex-start;
  margin-left: 10px;
  flex-grow: 1;
  -webkit-box-pack: start;
  margin-right: 10px;
  /* background-color: aqua; */
}

.nav-item {
  /* height: 70px; */
}

.nav-links {
  color: #000000;
  
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  margin-top: 3px;
  padding: 5px 15px;
  background-color: transparent;
  margin-right: 0px;
  height: 100%;
  font-family: "Lato", sans-serif;
}

.dis-none {
  display: none;
}
.button-div {
  display: flex;
  /* background-color: #F4F4F4; */
  /* background-color: #000000; */
  float: right;
  /* width: 30vw; */
  justify-self: flex-end;
  align-items: center;
  -webkit-box-pack: end;
  margin-right: 0;
  justify-content: flex-end;
}
.nav-links:hover {
  /* border-bottom: 2px solid #ffffff; */
  color: #000;
  padding-top: 10px;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.login-btn-main{
  background: #604fb2;
  border: none;
  outline: none;
  padding: 8px 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 7px;
  cursor: pointer;


}


@media screen and (max-width: 960px) {
  .login-btn-main{
    display: none;
  }
  .topright-menu {
    display: none;
  }
  
}

.fi-rr-align-right {
  color: #ffffff;
}

.nav-links-mobile {
  display: none;
}

.topright-menu-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.nav-links img {
  /* display: none; */
}

.nav-item1 {
  display: none;
}

.nav-links-mobile12 {
  display: none;
}

.nav-links-mobile-logout {
  display: none;
}

.special-nav-divvv-alt {
  display: none;
}

.bfuyfuc {
  width: 24px;
  height: 24px;
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar {
    background-color: transparent;
  }

  .navbar.active {
    /* background-color: #00519c; */
    /* margin-top: 0; */
  }

  .navbar-logo {
    color: #ffffff;
  }

  .navbar-logo:hover {
    color: #ffffff;
  }

  .NavbarItems {
    position: relative;
  }

  .sub-sidebar-text {
    font-size: 10px;
    text-align: center;
    display: block;
    align-items: center;
    /* padding-left: 7%; */
  }
  .nav-item {
    height: 50px;
    /* height: auto; */
  }

  .bfuyfuc {
    width: 24px;
    height: 24px;
    display: block;
  }
  .bfuyfuc12 {
    width: 24px;
    height: 24px;
  }

  .special-nav-divvv {
    display: none;
  }

  .special-nav-divvv-alt {
    display: block;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 130px;
    left: -100%;
    margin-left: 0;
    opacity: 1;
    background-color: #ffffff;
    transition: all 0.5s ease;
    /* border-right: 4px solid #00519C; */
    justify-content: flex-start;
    padding-left: 0;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;

    z-index: 1;
    /* background-color: aqua; */
    margin-left: 0;
    padding-left: 0;
    overflow-y: scroll;
  }

  .nav-links {
    text-align: left;
    padding: 1rem;
    width: 100%;
    margin-left: 0%;
    display: table;
    margin-bottom: 10px;
    font-size: 18px;
    color: #000000;
    /* border-bottom: 1px solid #000; */
    /* background-color: #00519C; */
  }
  .nav-links15425 {
    text-align: left;
    /* padding: 1rem; */
    /* width: 100%; */
    margin-left: 0%;
    margin-top: 30px;
    padding-top: 8px;
    padding-left: 20px;
    padding-bottom: 8px;
    padding-right: 20px;
    display: table;
    margin-bottom: 10px;
    font-size: 16px;
    color: #000000;
    border: 1px solid #000;
    border-radius: 4px;
    /* border-bottom: 1px solid #000; */
    /* background-color: #00519C; */
    text-decoration: none;
  }

  .nkjxbhvb {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }
  .nav-links img {
    display: inline-block;
  }

  .navbar-logo {
    position: absolute;

    top: -2px;
    left: 4%;
    /* transform: translate(10%, 70%); */
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 35%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fi-rr-cross-small {
    color: #ffffff;
  }

  .menu-icon img {
    width: 24px;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    border-radius: 4px;

    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    padding: 10px 23px;
    letter-spacing: 0.4px;
    position: absolute;
    top: 18px;
    right: 22%;
    border: none;
    background-color: #604fb2;

    transition: all 0.2s ease-out;
  }

  .topright-menu-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    position: absolute;
    top: 12px;
    right: 16%;
  }

  .nav-links-mobile-logout {
    background-color: #ffffff;
    color: #00519c;
    top: 18px;
    font-size: 18px;
    right: 14%;
    position: absolute;
    padding: 8px 17px;
    border-radius: 19px;
    display: block;
  }
  .nav-links-mobile12 {
    display: block;
    text-align: center;
    border-radius: 19px;

    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 8px 17px;
    position: absolute;
    top: 22px;
    right: 16%;
    /* border: 2px solid #00519C; */
    background-color: #fff;

    transition: all 0.2s ease-out;
  }

 
}

.profile-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0px;
  width: fit-content;
  /* margin-bottom: 5px; */
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  color: #000;
  font-weight: 400;
  /* background: #00519c; */
}

.profile-btn img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  z-index: 3;
  margin-right: 5px;
  object-fit: cover;
}

.profile-btn div {
  position: relative;
  background-color: #604fb2;
  color: #ffffff;
  height: fit-content;
  margin-right: 0px;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 6px;
}

.logout-btn {
  background-color: #604fb2;
  padding: 5px 10px;
  height: fit-content;
  border-radius: 5px;
  margin-left: 0px;
  font-size: 15px;
  cursor: pointer;
  color: #ffffff;
}




@media screen and (max-width: 600px) {
  .navbar {
    height: 80px;
    background-color: #fff;
    box-shadow: none;
    top: 60px;
    /* transition: 0.2s; */
  }


     
.profile-btn img {
  display: none;
}


  .navbar.active {
    background-color: #fff;
  
   
  }
  .navbar.active .navbar-logo {
    color: #ffffff;
  }

  .navbar.active .nav-links-mobile {
    /* background-color: #ffffff;
    color: #00519c; */
  }

  .navbar-logo:hover {
    color: white;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    border-radius: 19px;

    text-decoration: none;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 400;
    color: #fff;
    padding: 8px 17px;
    position: absolute;
    top: 12px;
    right: 16%;
    border: none;
    background-color: #604fb2;

    transition: all 0.2s ease-out;
  }

  .navbar-logo {
    margin-left: 0%;
    margin-top: 10px;
    left: 0;
    top: -10px;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    line-height: 37.5px;
  }

  .logo-img {
    width: 24px;
    height: 24px;
    background-image: url(../images/logo.svg);
    background-size: cover;
    margin-top: -3px;
    margin-right: 3px;
  }

  .navbar-logo img{
    width: 90px;
    margin-left: 20px;

  }

  .menu-icon {
    font-size: 24px;
    transform: translate(-70%, 50%);
    /*background-color: red;*/
  }

  .menu-icon img {
    width: 24px;
  }
  .fi-rr-align-right {
    color: #ffffff;
  }
  .fi-rr-cross-small {
    color: #ffffff;
  }
  .nav-links-mobile-logout {
    display: none;
  }

  .navbar.active .fi-rr-align-right {
    color: #00519c;
  }
  .navbar.active .fi-rr-cross-small {
    color: #00519c;
  }

  .nav-menu.active{
    /* background: #212121; */
    border-top: 1px solid #f4f4f4;
  }

  .logout-btn{
    border-radius: 4px;
    padding: 5px 15px;
  }

  .profile-btn div{
    border-radius: 4px;
    padding: 5px 15px;
  }

}
