.parent-mf-list{
    margin-top: 140px;
}
.parent-mf-list h1{
    text-align: center;
    font-size: 38px;
    padding-top: 25px;
}
.mf-list-2569{
    max-width: 1110px;
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    min-height: 75vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.mf-float-left{
    width: 25%;
    float: left;
}

.float-right-mf{
    width: 75%;
    float: right;
}

.unlock-mf-list{

    width: 85%;
    min-width: 200px;
    display: flex;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 12px;
    background-position: 50%;
    /* transition: background .3s; */
    cursor: pointer;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);
    border: 1px solid #ecedef;
    margin-top:3px;
}

.locked-mf-list{

    width: 85%;
    min-width: 200px;
    display: flex;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 12px;
    background-position: 50%;
    /* transition: background .3s; */
    cursor: pointer;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1);
    border: 1px solid #ecedef;
    background: #f6f6f7;
    margin-top: 3px;
}
.mf-list257496{
    width: 100%;
    border: 1px solid #ecedef;

}
.mf-list-card{
    width: 100%;
    border-bottom: 1px solid #ecedef;
    display: flex;
    align-items: center;
    height: 81px;
    cursor: pointer;
    height: auto;
    margin: 5px;
    padding: 5px;
    

}

.mf-list-card img{
    margin-left: 2%;
    width: 30px;
}

.kjhyj12{
    width: 69%;
    padding: 16px 29px 16px 59px;

}
.fund-name215{
    max-width: 398px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;

}

.interst-mf-fund{
    margin-right: 2%;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    text-align: center;
   
}
.interst-mf-fund span{
    font-weight: 400;
    color: #7c7e8c;
    
}

@media screen and (max-width: 980px) {
    .mf-list-2569{
        flex-direction: column;
        margin-left: 2.5%;
        margin-right: 2.5%;
        width: 95%;
    }
    .mf-float-left{
        margin-left: 4%;
        /* width: 100%; */
        float: none;

    }
    .float-right-mf{
        width: 100%;
        
        float: none;

    }
    
}

@media screen and (max-width: 600px) {

    .mf-list-2569
    {
        padding-top: 0;
    }

    .parent-mf-list h1{
        font-size: 22px;
    }

    .kjhyj12{
        width: 49%;
        padding: 16px 10px;
    
    }

    .fund-name215{
        font-size: 13px;
    }
    .interst-mf-fund{
        font-size: 12px;
    }
    
}