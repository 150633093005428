.profile-from input {
    /* background: rgba(196, 196, 196, 0.17); */
    /* border-radius: 5px; */
    padding: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    border: none;
    color: #000;
    margin: 10px;
    margin-left: 10px;
    margin-top: 15px;
    outline: none;
    transition: 0.3s;
}

.profile-info-container label{
    padding: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    border: none;
    color: #000;
    margin: 10px;
    margin-left: 10px;
    margin-top: 15px;
    outline: none;
    transition: 0.3s;
}

.profile-from-head{
    color: #604FB2;
    text-align: center;
    font-family: 'Roboto';
    font-size: 31px;
    font-weight: 600;
    /* line-height: 48px; */
    font-style: normal;
    font-feature-settings: 'liga' off;
/* top: 55px; */
/* left: 236px; */
/* width: 689px; */
/* height: 48px; */
}
.profile-from-head1{
    font-size: 25px;
    color: black;
    margin-left: 410px;
    display:grid;
}

.profile-form h3{
    text-align: center;
    font-family: 'Lato',sans-serif;
    font-weight: 600;
    margin-top: 20px;
}

.icon-box{
    width: 250px;
}

.hedfa{
    background-color: #37474F;
    color: #FFC727;
    border: none;
    outline: none;
    padding: 7px 20px;
    font-weight: 600;
    font-family: 'Lato',sans-serif;
    font-size: 21px;
    border-radius: 5px;
}
.hedfa a{
    text-decoration: none;
    color: #FFC727;
}

.hedfa a:hover{
    color: #FFC727;
}
.jkrd{
    border: none;
    background-color: #37474F;
    color: #FFC727;
    padding: 7px 20px;
    font-size: 21px;
    font-family: 'Lato',sans-serif;
    border-radius: 5px;
    font-weight: 600;

}

.abcde123 input{

    border: 1px solid #E7ECF4;
    padding-left: 15px;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #fafafc;
    outline: none;
    font-family: 'Lato',sans-serif;
}

.profile-from input:focus, label:focus{

    border: 1px solid #4914bc;

}

.financial-card{
    display: flex;
}
.jhtr select{
    border: 1px solid #ecedef;
    background: #fff;
    padding-left: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.label-inputs-com{
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    

}

.div-flex{
    /* display: flex; */
}
.financial-labels{
    display: flex;
    align-items: center;
    font-family: 'Lato',sans-serif;
    
}

.financial-inputs input{
    background: #fafafc;
    border: 1px solid #E7ECF4;
    border-radius: 4px;

}
.financial-labels input{
    margin-left: 30px;
    border: 1px solid #E7ECF4;
    border-radius: 4px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #fafafc;
    font-family: 'Lato',sans-serif;
}
.financial-labels select{
    border: 1px solid #E7ECF4;
    padding-left: 5px;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-top: 4px;
    background-color: rgba(196, 196, 196, 0.17);
    background: #fff;
    border-radius: 4px;
    color: #252729;
    font-weight: 600;
    font-family: 'Lato',sans-serif;
    margin-right: 10px;
}
.financial-labels label{
    margin-left: 0;
    color: #ABABAB;
    font-size: 17px;
    margin-right: 7px;
    margin-top: 10px;
    font-weight: 500;
    font-family: 'Lato',sans-serif;
}





/* .profile-form img {
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 90%;
    z-index: -1;
} */

.profile-form input {
    /* width: 40%; */
}

.profile-dropdowns {
    display: flex;
    flex-direction: column;
}

/* .profile-form select {
    width: 25%;
} */

/* .profile-from option {
    color: #000000;
} */





.profile-from button {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 23px;
    line-height: 39px;
    color: white;
    background: #604FB2;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    width: fit-content;
    padding: 5px 25px 5px 25px;
    margin-top: 15px;
    float: right;
    
}

.modified-img-alt{
    height: 230px;
}

.started-button16{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    color: #FFC727;
    background: #37474F;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    width: 200px;
    height: 55px;
    margin-top: 25px;
    float: right;
    position: absolute;
    right: 30px;
    bottom: 20px;
    font-family: 'Lato',sans-serif;
}


.fdbvbv{
    background-color: #37474F;
    color: #FFC727;
    border: none;
    outline: none;
    margin-top: 10px;
    padding: 5px 15px;
    border-radius: 3px;
}

.profile-head{
    
    font-style: normal;
    font-family: 'Lato',sans-serif;
    font-weight: 600;
    font-size: 31px;
    /* line-height: 30px; */
    margin-bottom: 20px;
   
}

.profile-subheading{
    font-size: 30px;
    line-height: 36px;
    font-family: 'Lato',sans-serif;
    font-weight: 600;
}


@media screen and (max-width: 600px) {
    .financial-card{
        flex-direction: column;
    }
    .label-inputs-com{
        width: 100%;
    }
    .financial-inputs{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .financial-inputs input{
        margin-left: 0;
        width: 100%;
    }
    .financial-labels{
        margin-left: 0;
        width: 90%;
        margin-left: 5%;
        /* background-color: #00519C; */
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .financial-labels h2{
        padding-left: 0;
        float: left;
        font-size: 20px;
        font-weight: 400;
    }
    .profile-form button{
        margin-left: 3%;
    }

    .age-input-58{
        width: 40px;
    }

    .profile-head{
        font-size: calc(1.3rem + .6vw);
        text-align: center;
        padding-top: 25px;
    }

    .profile-subheading{
        font-size: 24px;
        text-align: center;
    }
    .profile-button-row{
        display: flex;
        padding-left: 5%;
        padding-right: 5%;
    }
    .hedfa{
        font-size: 16px;
        padding: 4px 14px;
    }
    .jkrd{
        font-size: 16px;
        

    }
    .resulittttt{
        text-align: center;
    }
}


.profile-info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.riskfactor-close-button{
    display:flex;
    justify-content: center;
    margin: 1px;
}
