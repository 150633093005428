.footer {
  width: 100%;
  background: #f5f5f5;
  font-family: Rubik;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 10px;
  margin-top: 10px;
  position: relative;
  border-top: 10px solid #452ee1;
  box-shadow: 0 -10px 0 #ff4311;

}



.footer-container {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 5px;
  padding-bottom: 5px;

  width: 100%;
  align-items: flex-start;
}

.footer-content {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 40px;
  padding-bottom: 10px;
  margin-top: 20px;
  padding-left: 4%;
  padding-right: 4%;
  width: 100%;
  align-items: flex-start;
  font-size: 12px;
}

.anchor-img {
  width: 140px;
  height: 50px;
}

.sec-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  font-family: "Ubuntu";
  padding-top: 15px;
  margin-bottom: 25px;
  /* identical to box height */

  color: #000000;
}

.branding {
  width: 25%;
  /* background: #452ee1; */
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-logo {
  width: 40%;
  margin-top: -20px;
  margin-left: 30%;
  object-fit: contain;
  height: fit-content;
}

.branding,
.foot-address {
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;

  color: #000000;
  padding-top: 30px;
  text-align: center;

  /* font-family: 'Poppins',sans-serif; */
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-evenly; */
  margin-top: 15px;
}

.social-icons img {
  margin-right: 30px;
}

.rights {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 19px;
  padding-top: 5px;
  justify-content: center;
  /* identical to box height */

  color: #000000;
}

.products {
  width: 20%;
}

ul {
  list-style: none;
}

.products ul {
  padding-left: 0;
}

.products ul li a {
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 22px;
  justify-content: left;
  /* identical to box height */

  color: #000000;
  margin-bottom: 10px;
  /* padding-bottom: 20px; */
  display: inline-block;
  transition: 0.3s;
}

.products ul li a:hover {
  padding-left: 5px;
}

.company {
  width: 20%;
}

.company ul {
  padding-left: 0;
}

.company ul li a {
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;
  display: inline-block;
  margin-bottom: 10px;
  transition: 0.3s;
}

.company ul li a:hover {
  padding-left: 5px;
}

.quicklinks {
  width: 20%;
}

.quicklinks ul {
  padding-left: 0;
}

.quicklinks ul li a {
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;
  margin-bottom: 7px;
  display: inline-block;
  transition: 0.3s;
}

.quicklinks ul li a:hover {
  padding-left: 5px;
}

.applinks {
  position: relative;
  right: 0;
  display: flex;
  padding-top: 11px;
  padding-bottom: 7px;
  padding-left: 10px;
}

.applinks img {
  margin-right: 10px;
  width: 180px;
  height: 40px;
  object-fit: contain;
  /* border: 1px solid #919191; */
}



@media screen and (max-width : 860px) {

  .branding {
    width: auto;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .foot-address {
    text-align: center;
    font-size: 18px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10px;
  }

  .products {
    width: auto;
    margin-left: 5%;
  }

  .company {
    width: auto;
    margin-left: 5%;
    margin-right: 5%;
  }

  .quicklinks {
    width: auto;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 10px;
  }

  .sec-heading {
    font-size: 18px;
  }

  .products ul li a {
    font-size: 16px;

  }

  .company ul li a {
    font-size: 16px;

  }

  .quicklinks ul li a {
    font-size: 16px;

  }

  .applinks {
    justify-content: center;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .applinks a img {
    width: 140px;
    height: 40px;

  }


}