.media-arrow {
    position: absolute;
    right: 20px;
    bottom: 10px;
    transition: arrow;
}

.media-arrow:hover {
    animation-name: arrow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes arrow {
    /* 0% {
        transform: translateX(0px);
      } */
    25% {
        transform: translateX(10px);
    }
    50% {
        /* transform: translateX(0px); */
    }
    75% {
        transform: translateX(-10px);
    }
    /* 100% {
        transform: translateX(0px);
    } */
}