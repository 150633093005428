.editor-container {
    margin: 5px;
    border-radius: 40px;
}

.blog-editor {
    background: #C4C4C42B;
    margin-top: 0px;
    /* min-height: 300px; */
    height: 300px;
    padding: 10px;
    cursor: text;
    overflow: scroll;
}

.blog-editor input {
    /* height: 100%; */
    background-color: blue;
}

.blog-toolbar {
    background-color: #e5e5e5;
    margin-bottom: 0px;

}