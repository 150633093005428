@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.App12652{
     padding-top: 25px;
     padding-bottom: 30px;
}

.App12652 h1{
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 30px;
    padding-bottom: 30px;
}


.accordion__section {
  
    max-width: 1110px;
    width: 100%;
    margin: 0 auto;
}

.ghnihbre{

    display: flex;
    flex-direction: column;
    border: 1px solid #ecedef;
    border-radius: 5px;
    margin-bottom: 24px;
    background-color: #fff;

}


@media screen and (max-width : 600px) {
    .App12652 h1{
        font-size: 24px;
    }

    .ghnihbre{
        width: 95%;
        margin-left: 2.5%;
    }
    
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #fafafc; 
  color: #444;
  cursor: pointer;
  padding-left: 20px;
  padding-top: 25px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */


/* Style the accordion content title */
.accordion__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
