.mutualfund-poster {
  width: 100%;
  /* height: calc(100vh - 47px); */
  height: 80vh;
  margin-top: 60px;

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 7%;
}

.mutualfund-poster-left {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  position: relative;
  
}

.mutualfund-poster-left h1 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-size: 36px;
  font-weight: 500;
 
}

.mutualfund-poster-left h2{
   color:#604FB2;
   font-weight: 600;
   font-size: 42px;
   font-family: "Lato", sans-serif;
   
}
.mutualfund-poster-left p {
  /* margin: 5px; */
  margin-top: 20px;
  width: 90%;
  color: #454545;
  font-size: 21px;
  padding-right: 10%;
}




.apply-now-btn12 {
  border: none;
  background: #52ccc3;
  color: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
  display: inline-block;
  width: 280px;
  border-radius: 14px;
  font-size: 24px;
  margin-top: 25px;
  text-transform: uppercase;
}

.mutualfund-poster-right{
  width: 100%;

  display: flex;
  justify-content: center;
}
.mutualfund-poster-right img{
 height: 60vh;
}

.mutualfund-poster-right video {
  /* height: 60vh; */
  margin-top: 25px;
  width: 200%;
}


.apply-now-btn-mf1{
 
  background: #FF6D5C;
  border: none;
  /* padding: 15px 29px; */
  color: #fff!important;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  margin-top: 20px;
  width: 200px;
  height: 55px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.apply-now-btn-mf1:hover{
  color: #fff!important;

}



.parent-card-container21{
  width: 100%;
}

.card-container21{

  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

.mf-mobile{
  display: none;
}


@media screen and (max-width:1200px) {
  .mutualfund-poster-left{
    width: 50%;
    padding-left: 3%;
  }
  .mutualfund-poster-right{
    width: 50%;
    padding-right: 3%`;
  }
  .mutualfund-poster-right img{
    height: 40vh;
  }
  .mutualfund-poster-right video {
    width: 160%;
  }
  
  .mutualfund-poster {
    height: 60vh;
  }

}

@media screen and (max-width: 672px){

  .mutualfund-poster{
    height: auto;
    margin-top: 60px;
    flex-direction: column;
  }
  .mf-mobile {
    display: flex;
    justify-content: center;
    /* background: #191919; */
    padding-top: 20px;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .mf-mobile img{
    width: 70%;
  }
  .mf-mobile video {
    width: 110%;
  }
  .mutualfund-poster-left{
    
    padding-bottom: 20px;
    padding-left: 0;
    align-items: center;
    margin-top: -80px;
    width: 100%;
  }
  .mutualfund-poster-left h1{
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    color: #000;
    padding-top: 25px;
  }
  .mutualfund-poster-left h2{
    color: #00519C;
    font-size: 30px;
    text-align: center;
  }
  .mutualfund-poster-left p{
    color:#454545;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    width: auto;
    padding-right: 0;
    margin-top: 8px;
  }
  .mutualfund-poster-right{
    display: none;
  }
  
  .apply-now-btn-mf1{
    width: auto;
    height: auto;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 25px;
  }
}



.explore-card-parent{
  width: 100%;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: aqua; */
}




.explore-card{
  background: linear-gradient(180.03deg, #F8F8F8 0.03%, #FFFFFF 0.04%, #FFFFFF 60.38%, rgba(216, 216, 216, 0.28) 138.52%);
  box-shadow: 4px 4px 15px 2px #E3E4E4;
  border-radius: 12px;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  width: 300px;
  height: 330px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 40px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;

}

.stocktick-card {
  cursor: pointer;
  transition: 0.3s!important;
}

.explore-card:hover {
  transform: scale(1.05);
  
}


.stocktick-card:hover {
  transform: scale(1.03);
  
}



@media screen and (max-width: 600px) {

  .explore-card{
    margin-right: 0;
    margin-left: 0;
  }
  
}



.explore-card img{
  width: 90%;
  margin-left: 5%;
  height: 130px;
  margin-top: 10px;
  object-fit: contain;
}

.exploretitle {
  /* border: 1px solid black; */
  position: relative;
  padding-left: 5%;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  
  color: #000000;
}

.explorebutton {
  outline: none;
  border: none;
  padding: 10px 20px;
  background-color: #2f2e41;
  color: #FFC727;
  font-size: 17px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Lato',sans-serif;
  font-weight: 600;
  position: relative;
  right: 6%;
  bottom: 20px;
  margin-top: 15px;
  text-decoration: none;
  position: absolute;
  

  /* display: flex; */
  /* justify-content: flex-end; */
}
.explorebutton a{
  color: #FFC727;
  text-decoration: none;
}

.explorebutton:hover{
  color: #FFC727;
}

.visit-btn1{
  background: #FFFFFF;
  box-shadow: inset -4px -4px 10px 5px rgba(96, 79, 178, 0.2), inset 4px 4px 10px 5px rgba(96, 79, 178, 0.2);
  border-radius: 10px;
  text-decoration: none;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 20px;
  color: #000000;
}

.apply-now-btn-mf {
  color: #604FB2;
}


@media screen and (max-width: 600px) {

  .explore-card-parent{
    width: 90%;
   
    padding-top: 40px;
  }
  .explore-card-parent:nth-child(2){
    padding-top: 0px;
  }

  .explore-card{
    width: 100%;
    height: 150px;
    display: flex;
    border-radius: 15px;

  }
  .explore-card img{
    width: 40%;
    object-fit: contain;
    height: 100px;
    margin-left: 0;
  }

  
  .explorebutton{
    background: linear-gradient(180deg, #222222 0%, #1F1F1F 100%);
    border-radius: 15px;
    font-size: 10px;
    line-height: 13px;
    padding: 8px 15px;
    bottom: 13px;
    font-weight: 500;

    
  }

  .exploretitle{
    font-size: 18px;
    color: #000;
  }

 

  .yellow{
    color: #FFC400;
  }
  
}

.designnnnn{
  text-transform: uppercase;
  color:#ED6C1E;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
}













/* Discover mutual fund */

#discover_mf{
  background-color:#f1f3ff;
  padding: 10px;
  /* max-width: 1500px; */
  width: 100%;
 
  margin-left: auto;
  margin-right: auto;
  
}
#discover_mf h1{
  text-align: center;
  padding-top: 30px;
  font-size: 36px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}
.discover-mf-cards{
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
.discover-mf-card{
  width: 304px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  outline: none;
}
.discover-mf-card img{
  height: 70px;
}
.discover-mf-card h3{
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 24px;
}



@media screen and (max-width:600px) {

  #discover_mf{
   
  }
  #discover_mf h1{
    color: #000;
    font-size: 32px;
  }

  .discover-mf-cards{
    flex-direction: row;
    align-items: center;
  }
  .discover-mf-card{
   
   
    box-shadow: 4px 4px 15px 2px #575757;
    background: #604FB2;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 15px;
   
  }
  .discover-mf-card h3{
    color: #fff;
    font-size: 12px;
    font-weight: 400;
  }
  
}


.fundz-logo{
  width: 100px;
  margin-bottom: 12px;
}