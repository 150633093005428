.parent-parent-loan-card{
    display: flex;
    justify-content: center;
    
}

.webinar-heading1{
    font-size: 36px;
 
}
.desktop-webinar{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;
}
.webinar-card1{
    width: 360px;
    height: 360px;
    background: rgba(245, 242, 231, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    
}

.webinar-card1:hover{
    transform: scale(1.03);
}

.webinar-image{
    width: 100%;
    height: 200px;
}
.webinar-image img{
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
}

.webinar-title{
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    
    color: #000000;
    padding-top: 15px;
}

.webinar-description{
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    padding-top: 10px;
    padding-bottom: 15px;
    
    color: #000000;
    
}

.butttttonnnnn{
    display: flex;
    justify-content: center;
}


.webinar-card-btn1{
    border: none;
    background-color: #2f2e41;
    color: #FFC727;
    padding: 10px 18px;
    border-radius: 4px;
    position: absolute;
    font-size: 17px;
    font-family: 'Lato',sans-serif;
    font-weight: 600;
    bottom: 20px;
}

.mobile-webinar{
    display: none;
}
@media screen and (max-width: 600px) {

    .webinar-heading1{
        font-size: 17px;
    }

    .parent-parent-loan-card{
        width: 100%;
        flex-direction: column;
        padding-top: 20px;
    }

    .desktop-webinar{
        display: none;
    }

    .webinar-card-btn{
        background: #000000;
        font-size: 14px;
        padding: 5px 14px;
        color: #fff;
        border: none;
        padding: 6px 18px;
        border-radius: 4px;
    }

    .mobile-webinar{

        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .webinar-card{
        background: linear-gradient(180deg, #C8E3FF 0%, #0155AE 100%);
        width: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 5%;
        margin-bottom: 20px;
        transition: 0.3s;
        

    }

    .webinar-card:hover{
        transform: scale(1.04);
    }

    .webinar-left{
    
        width: 70%;
    }
    .webinar-left h1{
        font-size: 16px;
        font-weight: 500;
    }
    .webinar-right{
        width: 30%;
    
    }
    .webinar-right img{
        height: 80px;
        width: 80px;
        object-fit: cover;
        border-radius: 4px;
    }
    
}













/* Learn */
.Learn{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.hrf-btn{
    
    border-radius: 4px;
    border: none;
    background-color: #2f2e41;
    color: #FFC727;
    padding: 10px 18px;
    font-size: 17px;
    font-family: 'Lato',sans-serif;
    font-weight: 600;
    
}
.hrf-btn button{
    font-size: 17px;
    color: #FFC727!important;
    font-family: 'Lato',sans-serif;
    font-weight: 600;
    
}