.newblog-root-container {
  width: 100%;
  
}

.create-new-blog-container {
  width: 100%;
  margin-top: 70px;
  background-color: #fff;
  margin-bottom: 20px;
}



.create-new-blog {
  padding-top: 50px;
  width: 90%;
  margin-left: 5%;
  padding-bottom: 40px;
  background-color: #fff;
}

.nodi-form-class{
  width: 100%;
}


.blog-category {
  vertical-align: middle;
  font-size: var(--new-blog-input-ft-size);
  line-height: 2.25rem;
  font-weight: 600;
}

.blog-category select {
  padding: 5px;
  font-size: 20px;
  margin-left: 10px;
  border-radius: 0.375rem;
  font-family: Rubik;
}

.enter-image-url {
  padding: 8px;
  padding-left: 12px;
  font-size: 20px;
  border-radius: 4px;
  border: 2px solid #00519c;
  color: #00519c;
  font-family: Rubik;
  margin-top: 10px;
  transition: 0.2s;
  outline: none;
}

.enter-image-url:focus{
  border: 2px solid #389af7;
}

.tags-panel {
  margin-bottom: 30px;
}

.blog-add-tags {
  vertical-align: middle;
  font-size: var(--new-blog-input-ft-size);
  line-height: 2.25rem;
  font-weight: 600;
}

.blog-slug {
  vertical-align: middle;
  font-size: var(--new-blog-input-ft-size);
  line-height: 2.25rem;
  font-weight: 600;
}

.blog-slug input {
  padding: 5px;
  font-size: 20px;
  margin-left: 10px;
  border-radius: 2px;
  border: 2px solid #00519c;
  color: #00519c;
  font-family: Rubik;
}

@media screen and (max-width: 1100px) {
  .create-new-blog {
    padding: 3rem 1rem;
  }
}

.create-new-blog__upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.375rem;
}

@media screen and (max-width: 800px) {
  .create-new-blog__upper {
    margin-bottom: 1.5rem;
  }
}

.create-new-blog__upper__left > a {
  margin-left: -0.125rem;
  padding-top: 0.5rem;
}

.create-new-blog__upper__left-svg {
  margin-right: 1rem;
  vertical-align: middle;
}
.create-new-blog__upper__left-text {
  vertical-align: middle;
  font-size: var(--new-blog-input-ft-size);
  line-height: 2.25rem;
  font-weight: 600;
}

.create-new-blog__form{
  width: 100%;
}
.blog-btns {
  font-weight: 600;
  border-radius: 4px;
  font-size: var(--new-blog-dialog-caption-ft-size);
  padding: 0.5rem 0.75rem;
  font-family: Poppins;
}

.blog-btns:hover {
  cursor: pointer;
  color: var(--hover-color);

  transition: color ease 0.25s;
}

.create-new-blog__upper__right__btn_1 {
  border: 2px solid #00519c;
  margin-right: 2.25rem;
}

.create-new-blog__upper__right__btn_2 {
  padding: 0.625rem 2rem;
  background-color: #00519c;
  color: #fff;
}

@media screen and (max-width: 1100px) {
  .create-new-blog__upper__left-svg {
    margin-right: 0.25rem;
  }
  .blog-btns {
    padding: 0.25rem 0.5rem;
  }
  .create-new-blog__upper__right__btn_1 {
    margin-right: 0.5rem;
  }
  .create-new-blog__upper__right__btn_2 {
    padding: 0.375rem 1.5rem;
  }
}

.display-flex{
  display: flex;
}

.input-field-box{
  width: 98%;
  
}

.chedk{
  margin-right: 2%;
}

.create-new-blog__form__input-text {
  width: 97%;
  margin-right: 0;
  padding: 10px;
  padding-left: 17px;
  background: #C4C4C42B;
  font-family: Poppins;
  font-size: 18px;
  border-radius: 3px;
  border: none;
  outline: none;
  margin-bottom: 1rem;
}

.create-new-blog__form__input-upload__wrapper {
  background: #C4C4C42B;
  border-radius: 3px;
  padding: 1.125rem 1.5rem;
  position: relative;
  margin-bottom: 1rem;
}

.create-new-blog__form__input-upload__caption {
  margin-bottom: 2rem;
  font-size: 18px;
}

.create-new-blog__form__input-upload__input_dialog {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
}
.create-new-blog__form__input-upload__input_dialog > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.create-new-blog__form__input-upload__input_dialog > div > svg {
  margin-bottom: 1rem;
}
.create-new-blog__form__input-upload__input_dialog > div > .dialog__caption {
  margin-bottom: 1rem;
  font-size: var(--new-blog-dialog-caption-ft-size);
}

.create-new-blog__form__input-upload__input > input[type="file"] {
  display: None;
}

.create-new-blog__form__input-upload__input {
  cursor: pointer;
  border-radius: 29px;
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  color: #fff;
  margin-bottom: 0.5rem;
  background-color: #00519c;
}

.prompt {
  margin-bottom: 0;
  position: absolute;
  bottom: 1.75rem;
}
.create-new-blog__input-value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-new-blog__input-value > p {
  font-size: 1rem;
}

.create-new-blog__input-value > svg {
  cursor: pointer;
}

.upload-error {
  margin-bottom: 0;
  font-weight: 600;
  color: var(--error-color);
}

.create-new-blog__blog-content__caption {
  
  margin-bottom: 1rem;
  font-family: Roboto;
  font-weight: 500;
}

.create-new-blog__blog-content__caption a {
  color: rgb(84, 84, 219);
}
.create-new-blog__blog-content__caption a:hover {
  text-decoration: underline;
}

.create-new-blog__blog-content__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-new-blog__form__textarea {
  resize: none;
  font-size: 1rem;
  height: 100vh;
  padding: 1rem 12px;
  margin-right: 1rem;
  margin-bottom: 0;
  width: 45%;
}

.create-new-blog__blog-content__markdown {
  border-radius: 1.25rem;
  padding: 1.5rem 5px;
  background-color: var(--new-blog-input-bg);
  overflow-y: auto;
  word-break: break-all;
  height: 100vh;
  width: 45%;
  padding-left: 12px;
}
.create-new-blog__blog-content__markdown img {
  width: 100%;
}

.preview-image-wrapper {
  width: 40%;
  display: inline-block;
  padding: 0.875rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFAE00FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
@media screen and (max-width: 1100px) {
  .preview-image-wrapper {
    width: 100%;
  }
}

.preview-image-wrapper > p {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: var(--new-blog-input-ft-size);
}

.preview-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 50vh;

  animation: increase-height ease 1s;
}

@keyframes increase-height {
  0% {
    height: 0px;
  }
  100% {
    height: 50vh;
  }
}

/* modal css */
.submit-success {
  background-color: #e5e5e5;
  text-align: center;
  padding: 2rem 1rem 1rem;
  border-radius: 7px;
}
.submit-success svg {
  margin-bottom: 1rem;
}

.submit-success h3 {
  margin-bottom: 1rem;
}
.submit-success p {
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.submit-success .btn {
  font-weight: 600;
  margin-bottom: 1rem;
}
