.background1234{
    position: fixed;
    max-width: 900px;
    width: 100%;
    height: 90vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;

}

.parent-login{
    width: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 123;
    

}

.hedaaaa{
   
    width: 100%;
    max-width: 900px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
   
    
}

@media screen and (max-width : 600px) {
    .hedaaaa{
        width: 100%;
        left: 0;
        top: 0;
        height: 100vh;
        /* background: rgba(0, 0, 0, 0.8); */

    }
    
}