.risk-modal25 {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 30000;
}

.slider-btn {
  background: #7258f4;
  border-radius: 4px;
  width: 200px;
  height: 55px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  margin-left: 5px;
}

.mf-list-card213 {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  height: 81px;
  

  position: relative;
  z-index: 0;
  margin-bottom: 10px;
}

.mf-list-card213 h3 {
  font-size: 14px;
  position: absolute;
  bottom: 5px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  color: #000;
}

.kljh {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  filter: blur(4px);
}
.mf-list-card213 img {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 5555;
  bottom: 38px;
}

.riskfactor-modal-box{
  height: 280px;
}

@media screen and (max-width:1200px) {
  .riskfactor-modal-box{
    height: 300px;
  }

}

@media screen and (max-width:1000px) {
  .riskfactor-modal-box{
    height: 300px;
  }

}

@media screen and (max-width:672px) {
  .riskfactor-modal-box{
    height: 400px;
  }

}

@media screen and (max-width:600px) {
  .riskfactor-modal-box{
    height: 400px;
  }

}