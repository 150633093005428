.qr-parent {
  width: 100%;
  background: rgba(96, 79, 178, 0.2);
  height: calc(70vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.qr-box {
  position: relative;
  width: 100%;
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 13px solid #604fb2; */
  box-sizing: border-box;
}

.qr-content {
  width: 100%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
}

.qr-text {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.02em;
  padding-left: 18%;
  color: #000000;
}

.qr-sub-text {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  padding-left: 18%;
  padding-top: 10px;
  /* identical to box height */
  font-family: Ubuntu;
  color: #646464;
}

.qr-code {
  width: 175px;
  height: 175px;
  object-fit: cover;
  margin-left: 18%;
  margin-top: 20px;
}

.qr-img-box {
  width: 70%;
  margin-right: 5%;
  margin-top: 10px;
}

.qr-img-box img {
  width: 100%;
  /* object-fit: contain; */
  height: 350px;
}

@media screen and (max-width: 800px) {
  
 
  
  .qr-img-box img {
    height: 100%;
    width: 100%;
  }
 
  .qr-text {
    font-size: 30px;
  }
 
}

@media screen and (max-width : 600px) {
  .qr-parent{
    height: auto;
    padding-bottom: 20px;
    
  }
  .qr-box{
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .qr-img-box {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
}

 .qr-content{
   padding-right: 0;
   padding-left: 0;
 }

 .qr-text, .qr-sub-text {
   padding-left: 10%;
 }
}