.newsletter-parent {
  position: relative;
  background: rgba(255, 118, 102, 0.2);
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.max-width{

  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0 auto;
  width: 100%;
  max-width: 1150px;

}

.newsletter-img {
  width: 100%;
  margin-left: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.newsletter-img img {
  border-radius: 6px;
  width: 46%;
  display: -webkit-box;
  margin-left: auto;
}

.newsletter-content {
  width: 100%;
}

.newsletter-heading {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.03em;
  margin-top: 3px;
  color: #000000;
  padding-left: 10%;
}

.input-div-for-newsletter {
  width: 40%;
  margin-left: 10%;
  margin-top: 20px;
}
.input-div-for-newsletter input {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  padding-left: 15px;
  height: 48px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  border: 1px solid #d9d9d9;
  /* identical to box height */

  color: #000000;
  outline: none;
  font-family: "Ubuntu";
  transition: 0.3s;
}

.input-div-for-newsletter input:focus {
  border: 1px solid #a993e2;
  box-shadow: 0px 0px 0px 2px rgba(182, 123, 242, 0.5);
}
.newsletter-texttt {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
  /* identical to box height */

  color: #000000;
  padding-left: 10%;
  padding-top: 10px;
}

.newsletter-btn {
  background-color: #000;
  border: none;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  margin-left: 10%;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.3s;
}



@media screen and (max-width: 800px) {
  .newsletter-parent {
    height: auto;
   
  }
  .max-width{
    flex-direction: column;

  }
  .newsletter-content {
    position: relative;
    right: 0;
    z-index: 1;
    margin-top: 30px;
  }
  
  .newsletter-img {
    position: relative;
    margin-top: 30px;
    top: 20px;
    z-index: 0;
    width: 90%;
    margin-left: 5%;
    
  }

  .input-div-for-newsletter{
    width: 85%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .newsletter-img img {
    filter: brightness(60%);
    width: 100%;
    
  }
  .newsletter-heading {
    margin-top: 10%;
  }
}