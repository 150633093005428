.p_main {
    /* width: 90%; */
    display: block;
    margin-top: 150px;
    margin-bottom: 20px;
}

.p_article{
    /* width: 90%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0px auto;
    padding: 20px 0;
    border-radius: 5px;
}
.p_article:nth-child(even) {
    background-color: #eef3ff;
}
.p_rev {
    flex-direction: row-reverse !important;
}


.p_head {
    padding: 30px 0px;
    width: 80%;
    margin: auto;
    font-weight: 300;
    font-size: 32px;
    color: #604FB2;
}
.p_head > hr {
    height: 3px;
}

/* .p_company {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0 20px;
    background-color: #eef3ff;
}
.p_company div{
    margin: 0 20px;
} */

.p_left {
    width: 49%;
    margin: 0 auto 10px;
}

.p_right {
    width: 49%;
    margin: 0 auto;
}

.p_title {
    font-size: 20px;
    font-style: italic;
    width: 70%;
    margin: 0 auto 5px;
}

.p_data {
    width: 70%;
    margin: 0 auto;
}

.p_img {
    width: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.p_company {
    width: 80%;
    margin: 5px auto;
}

.p_enroll {
    padding: 5px 10px;
    margin: 0 auto;
    border-radius: 5px;
}


@media screen and (max-width: 900px) {
    .p_left {
        width: 100%;
    }

    .p_right {
        width: 100%;
    }
    
    .p_rev {
        flex-direction: row;
    }
}

.contact-form212 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: -4px 4px 24px 0 rgb(0 0 0 / 20%);
    padding: 10px 5px;
    border-radius: 15px;
}

.contact-form212 input {
    border: 1px solid #ecedef;
    outline: none;
    width: 100%;
    font-size: 17px;
    font-family: 'Lato', sans-serif;
    border-radius: 10px;
}

.contact-form212 textarea {
    border: 1px solid #ecedef;
    outline: none;
    width: 100%;
    font-size: 17px;
    font-family: 'Lato', sans-serif;
    border-radius: 10px;
}