.blogs-page{
  width: 100%;
  margin-top: 135px;

}

@media screen and (max-width:600px) {
  .blogs-page{
    margin-top: 160px;
  }
  
}


#arrow {
  font-weight: 50;
  margin-right: 20px;
  color: #000000;
}

#arrow:hover {
  position: relative;
  color: #ffbd06;
  z-index: 100;
  transition: 0.5s;
  transform: scale(1.2);
}

#whole {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#core {
  margin-top: 0px;
  width: 65%;
}

#side {
  /* position: fixed;
  right: 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #000000;
  width: 35%;
}

#side ol {
  margin-left: 100px;
}

#categories {
  margin-top: 20px;
  margin-left: 10px;
}

#nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  padding-left: 2%;
}

#drop-down {
  position: relative;
  z-index: 3;
  margin-top: 50px;
  margin-left: 20px;
  width: fit-content;
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  cursor: pointer;
}

#down-arrow {
  position: relative;
  left: 8px;
  scale: 0.7;
}

#side-menu {
  position: relative;
  z-index: 1;
  width: fit-content;
  margin-left: 5%;
  margin-top: 70px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 2vi;
  line-height: 10vi;
  color: #000000;
}

#side-menu li {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8vb;
  line-height: 7vb;
  color: #000000;
}

#side-menu li:hover {
  color: #00519c;
  transition: 0.5s;
}

#subs {
  z-index: 1;
}

.btn {
  padding: 0.5rem 3rem;
  background-color: #00519c;
  font-family: Rubik;
  font-weight: 500;
  font-size: 1.25rem;
  border: none;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.25s;
}

#category-head {
  margin-bottom: 6px;
  font-weight: bolder;
}

#link {
  text-decoration: none;
  line-height: 40px;
}

#drop-title {
  position: relative;
  display: flex;
  height: fit-content;
  width: fit-content;
}

#sort-orders {
  position: absolute;
  z-index: 100;
  margin-left: 20px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: fit-content;
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  text-justify: center;
  border-radius: 10px;
  background-color: #ffffff;
}

#sort-orders-inactive {
  display: none;
}

#sort-order {
  cursor: pointer;
  text-justify: center;
  line-height: 30px;
}

#sort-order:hover {
  color: #00519c;
  transition: 0.5s;
}

#sort-direction {
  height: 15px;
  position: absolute;
  right: -25px;
  top: 50px;
  /* top: 120px; */
  cursor: pointer;
  /* margin-left: 4px; */
}

@media screen and (max-width: 1300px) {
  /* #sort-direction {
    left: 95%;
  } */
  .icon {
    height: 35px;
  }
  .body {
    margin-left: 6%;
  }
}

#load-inactive {
  left: 200px;
}

#access-bar {
  position: fixed;
  top: 20;
  display: none;
  background-color: #ff7666;
  width: 100%;
  z-index: 10;
  white-space: nowrap;
}

#access-drop-down {
  position: relative;
  z-index: 3;
  width: fit-content;
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
}

#access-down-arrow {
  position: relative;
  scale: 0.7;
}

.access-items {
  position: absolute;
  z-index: 9;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  height: fit-content;
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-justify: center;
  border-radius: 10px;
  /* border: 3px solid #000000; */
  background-color: #ffffff;
  animation-name: access-items;
  animation-duration: 0.8s;
}

@keyframes access-items {
  0% {
    opacity: 0%;
  }
}

.access-items-inactive {
  display: none;
}

#access-item {
  cursor: pointer;
  text-justify: center;
  font-family: Roboto;
  line-height: 30px;
  text-decoration: none;
  color: #000000;
}

#access-item:hover {
  color: #00519c;
  transition: 0.5s;
}

#access-sort-direction {
  scale: 0.7;
  position: relative;
  cursor: pointer;
  right: -30;
}

#access-drop-title {
  /* border: 2px solid #000000; */
  display: flex;
  flex-direction: row;
  color: #ffffff;
  align-items: center;
  margin-right: 8px;
  padding: 6px;
  background-color: #00519c;
  border-radius: 8px;
}

#access-drop-title:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

@media screen and (max-width: 800px) {
  .image {
    max-width: 630px;
  }
  #access-bar {
    display: flex;
    flex-direction: row;
    height: 60px;
    border: 1px solid #000000;
    align-items: center;
    justify-content: flex-end;
  }
  #side {
    display: none;
  }
  #whole {
    justify-content: center;
  }
  #core {
    width: 95%;
  }
  #access-drop-container {
    justify-content: center;
  }
  #load {
    justify-self: center;
  }
  #likes-icon {
    font-size: 24px;
    right: 10%;
  }
  #likes {
    font-size: 13px;
    right: 10%;
  }
  .bookmark-icon {
    position: relative;
    width: 28px;
    height: 35px;
  }
  #bmi {
    margin-left: 88%;
  }
  .footing a {
    margin: 0;
  }
  #core {
    margin-top: 100px;
  }
}

@media screen and (max-width: 420px) {
  .bookmark-icon {
    left: 300%;
    height: 38px;
  }
  #bmi {
    margin-left: 60%;
  }
  #likes {
    font-size: 12px;
  }
  .footing {
    width: 100%;
    justify-content: space-evenly;
  }
  .footing a {
    font-size: 13px;
    margin: 2px;
  }
  .username {
    font-size: 15px;
  }
  .body {
    margin: 0;
    align-self: center;
  }
}

#subscribe-form {
  position: relative;
  z-index: 1;
  /* top: 550px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

#mail-label {
  position: absolute;
  left: 0px;
  top: 30px;
  top: 0.875rem;
}

#input-email-id-blogs {
  width: 90%;
  margin-bottom: 2rem;
  border: 2px solid #7b7b7b;
  font-size: 1.125rem;
  border-radius: 7px;
  padding: 1rem 0rem 1rem 4rem;
  outline: none;
}

#subscribe-btn {
  padding: 0.5rem 3rem;
  background-color: #00519c;
  font-family: Rubik;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.25rem;
  border: none;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.25s;
}

#subscribe-btn:hover {
  color: #777;
}
