.mff-design{
    width: 100%;
    height: 70vh;
    /* background: #604FB2; */
    position: relative;
}

#tsparticles{
    width: 100%;
    height: 100%;
    position: absolute;
}

.huzxn56{
    max-width: 1280px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}
.text-div58 h1{
    color: #fff;
    font-size: 60px;
    font-weight: 700;
    line-height: 84px;
    font-family: "Lato", sans-serif;


}
.text-div58 p{
    color: #fff;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    font-family: "Lato", sans-serif;
    margin-top: 10px;
}
.huzxn56 img{
    height: 350px;
}

@media screen and (max-width:1250px ) {
    
    .huzxn56{
        padding-left: 5%;
    }
    .huzxn56 img{
        height: 450px;

    }
}

@media screen and (max-width:999px ) {
    
    .huzxn56{
        padding-left: 5%;
        flex-direction: column;
        align-items: flex-start;
    }
    .huzxn56 img{
        height: 450px;

    }
}

@media screen and (max-width:600px ) {
    .mff-design{
        height: auto;
        
    }
    .huzxn56{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .huzxn56 h1{
        font-size: 32px;
        line-height: 38px;
    }
    .huzxn56 p{
        font-size: 18px;
        padding-right: 10%;
    }
  
    .huzxn56 img{
        margin-left: 2.5%;
        width: 95%;
        height: auto;

    }
}