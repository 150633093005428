:root {
  background-color: #e5e5e5;
}

.blogs-page {
  margin-top: 70px;
  width: 100%;
}

#page {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  justify-content: center;
}

#sidebar {
  margin-left: 38px;
  width: 10%;
}

.blog-side-menu {
  width: 26%;
  float: right;
}

#body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
}

@media screen and (max-width: 930px) {
  #sidebar {
    display: none;
  }
  #body {
    width: 100%;
    flex-direction: column;
  }
  #image {
    width: 100%;
  }
  #featured-image {
    width: 100%;
  }
  #blog-body {
    font-size: 4px;
  }
  #comments-box {
    font-size: 4px;
  }
  #body {
    justify-content: center;
    align-items: center;
  }
  .blog-side-menu {
    width: 96%;
  }
  #content {
    width: 100%;
  }
}

#author-info {
  align-self: center;
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  margin-bottom: 40px;
}

.posts-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* border: 1px solid #00519c; */
  margin-bottom: 20px;
  font-family: Rubik;
  justify-content: center;
  border: 1px solid #ecedef;
  border-radius: 5px;
}

.posts-list-title {
  display: flex;
  /* justify-content: left; */
  font-size: 20px;
  width: 100%;
  /* background-color: #00519c; */
  color: #000000;
  align-self: center;
  justify-self: center;
  font-weight: bolder;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ecedef;

}

.posts-list {
  width: 100%;
}

.post-container {
  /* position: relative;
  height: fit-content;
  width: 100%; */
  /* padding: 10px; */
  /* cursor: pointer;
  transition: 0.2s;
  margin: 5px 0px 5px 0px; */


  border-bottom : 1px solid #ecedef;
  cursor: pointer;
  position: relative;
}

.post-container :hover {
  background-color: #4186e02e;
  transition: 0.5s;
}

/* .post-container :hover {
  color: #00519c;
  transform: scale(2);
} */

.post-container img {
  position: absolute;
  z-index: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  filter: brightness(40%);
}

.post-container div {
  position: relative;
  padding-top: 11px;
  padding-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
  width: 100%;
  /* color: #e5e5e5; */
  font-family: Poppins;
  /* font-weight: 700; */
  z-index: 1;
}

.post-container span {
  font-family: Poppins;
  color: #ffffff;
  background-color: #5d5bec;
  padding: 4px 10px;
  font-size: 10px;
  border-radius: 3px;
  white-space: no-wrap;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

#like {
  height: 21.78px;
  width: 19px;
  margin-right: 10px;
  cursor: pointer;
}

#like :hover {
  transform: scale(1.1);
}

#share {
  height: 20px;
  width: 19px;
  cursor: pointer;
}

#share :hover {
  transform: scale(1.1);
}

#profilepic {
  width: 90px;
  height: 90px;
  left: 580px;
  top: 120px;
  border: 2px solid #00519c;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 90px;
  margin-right: 40px;
  cursor: pointer;
}

#profilepic :hover {
  transform: scale(1.1);
}

#username-blog {
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 15px;
}

#badge-info {
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  /* identical to box height */

  color: #828282;
}

#info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#author {
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  width: fit-content;
}

#author-username {
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 28px;
  color: #000000;
  margin-top: 13.5px;
}

#author-bio {
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #5a5a5a;
  margin-bottom: 10px;
}

#icons {
  margin-top: 10px;
}

#content {
  width: 80%;
  margin-left: 20px;
  max-width: 1000px;

}

#head {
  width: 98%;
  /* margin-left: 40px; */
}

#head-info {
  width: 90%;
 
  margin-bottom: 20px;
}
#time-1{
  color: #828282;
}

#title {
  font-family: 'Poppins',sans-serif;
  /* font-family: PT Serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 160%;
  letter-spacing: 0.01rem;
  line-height: 40px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 16px;
   padding-right: 10%;
}

#time-date {
  font-family: 'Poppins',sans-serif;
  /* font-family: PT Serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  color: #828282;
  display: flex;
  align-items: center;
}

.cald{
  margin-right: 5px;
}

.img-courtesy{
  padding-top: 20px;
}

#views {
  right: 0;
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #828282;
}

#eye {
  height: 16px;
  width: 24px;
  margin-right: 10px;
}

#image {
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #828282;
}

#featured-image {
  width: 98%;
  height: auto;
  border-radius: 8px;
  max-height: 500px;
  object-fit: cover;
}

#blog-body {
  margin-top: 60px;
  margin-bottom: 60px;
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}

#comments-box {
  position: relative;
}

#comments {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
}

#comment-input {
  padding: 10px 20px 10px 20px;
  width: 90%;
  height: 130px;
  border-radius: 14px;
  border: none;
  resize: none;
  overflow: scroll;
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

#post-btn {
  position: absolute;
  right: 50px;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: space-around;
  width: 108px;
  height: 40px;
  border-radius: 12px;
  background-color: #00519c;
  justify-self: right;
  margin-bottom: 20px;
  cursor: pointer;
}

/* #post-btn :hover {
  color: #ff4311;
  transition: 0.5s;
} */

#post-btn :active {
  transform: translateY(4px);
}

#post {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 34px;
  color: #e5e5e5;
}

#replies {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

#userpic {
  width: 59px;
  height: 59px;
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 50px;
  margin-right: 23px;
  cursor: pointer;
  object-fit: cover;
}

#profile-name {
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 20px;
}

#comment {
  font-family: 'PT Serif',sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #414141;
  margin-bottom: 30px;
}

#reply-dropdown {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-content: center;
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #525252;
  margin-bottom: 10px;
  
  cursor: pointer;
}

#chevron-down {
  position: relative;
  display: inline;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

#chevron-up{
  position: relative;
  display: inline;
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  margin-left: 10px;

}

#reply-btn {
  font-family: PT Serif;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #7b7b7b;
  margin-bottom: 28px;
  cursor: pointer;
}

#reply-username {
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 30px;
  color: #7b7b7b;
  margin-right: 21px;
}

#reply {
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 30px;
  color: #414141;
}

#reply-box {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.tags-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tags-list-sel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tags-list div {
  position: relative;
  padding: 2px 5px 2px 5px;
  margin: 5px;
  background-color: #e5e5e5;
  color: #000000;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
}

.tags-list div :hover {
  transform: scale(1.1);
}

.tags-list-sel div {
  position: relative;
  padding: 2px 5px 2px 5px;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 8px;
  background-color: #5d5bec;
  color: #e5e5e5;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.tags-list-sel button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 8px;
  border-radius: 10px;
  opacity: 100%;
  border: none;
}

.tags-list-sel button :hover {
  opacity: 100%;
  transform: scale(1.05);
}

#choose-tags {
  background-color: transparent;
  font-style: italic;
  justify-self: center;
  align-self: center;
  color: #00519c;
  line-height: 2.25rem;
  font-weight: 600;
}


@media screen and (max-width : 600px) {
  #content{
    margin-left: 0;
    width: 90%;
  }
  #title{
    font-size: 19px;
    line-height: 27px;
    padding-right: 0;
  }
  #head{
    width: 100%;
  }
  #featured-image{
    width: 100%;
  }

  #userpic{
    width: 40px;
    height: 40px;
  }

  #profile-name{
    font-family: 'Poppins',sans-serif;
    margin-bottom: 10px;
  }
  #comment{
    font-size: 18px;
    margin-bottom: 10px;
  }

  #reply-username{
    font-size: 20px;
  }
  #reply{
    font-size: 18px;
    line-height: 22px;
  }
}