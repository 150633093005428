
.our-services-parent{
  width: 100%;
  background-color: #ffffff;
  height: auto;
  padding-bottom: 90px;
  /* margin-top: 10px; */
}

@media screen and (max-width: 1050px) {

  .our-services-parent{
    height: auto;
  }
  
}
.ourservices {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  padding-top: 50px;
  padding-bottom:10px;
}

.service-tag {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;

  color: #000000;
}

.service-sub-tag {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  line-height: 32px;
  /* identical to box height */
  padding-top: 10px;

  color: #797979;
}

.service-parent {
  display: flex;
  margin-top: 40px;
  justify-content: center;
}


.service-card {
  
  border-radius: 23px;
  width: 300px;
  height: 400px;
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.3s;
  padding: 20px;
  box-shadow: 10px 5px 10px rgba(255, 118, 102, 0.25);
  transform-style: preserve-3d;
  perspective: 600px;
  transition: 0.5s;
}

.service-card:hover .service-card-front{

  transform: rotateY(-180deg);


}

.service-card:hover .service-card-back{
  transform: rotateY(0deg);
}

.service-card-front{
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(0deg);
  transition: 0.5s;
  border-radius: 23px;
  background: rgba(255, 118, 102, 0.1);
  left: 0;
  top: 0;

}



.service-card-back{

    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    transition: 0.5s;
    position: absolute;
    background-color: #FF7666;
    left: 0;
    top: 0;
    border-radius: 23px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}


.service-img-box {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 50px;
}

.service-img-box img{
  width: 80%;
  height: 200px;
  margin-bottom: 10px;
  object-fit: contain;
}



@font-face {
  font-family: "Ubuntu";
  src: url(../../fonts/Ubuntu-Regular.ttf);
  font-style: normal;
}

.box-content {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  padding-top: 27px;
  padding-left: 5%;
  padding-right: 5%;

  color: #000000;
}

/* .service-card:hover {
  background: #ff7666;
} */

.hover-title h2{
  font-family: 'Lato',sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  padding-left: 5%;
  padding-right: 5%;
  color: #000000;
}

.hover-info p{
  padding-left: 5%;
  padding-right: 5%;
  color: #ffffff;
  padding-top: 10px;
  font-size: 17px;
  line-height: 26px;
}



@media screen and (max-width: 1000px) {
  .service-parent {
    flex-direction: column;
  }
  .service-card {
    
    margin-bottom: 50px;
    width: 90%;
    margin-left: 3%;
  }
  
  
  
  
  .near-content {
    width: 60%;
  }
}


@media screen and (max-width:600px) {
  .our-services-parent{
    height: auto;
    padding-bottom: 0;
    
  }
  
  
  .service-card{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 0px;
   
    height: 250px;
    
  }
  .service-img-box img{
    height: 100px;
    width: auto;
  }
  .service-tag{
    font-size: 30px;
    line-height: 36px;
  }
  .service-sub-tag{
    padding-left: 3%;
    padding-right: 3%;
  }

 
  .box-content{
   
    font-size: 20px;
    font-weight: 600;
  }
  .hover-title{
    width: 100%;
  }
  .hover-title h2{
    color: #ffffff;
    font-size: 17px;
    line-height: 22px;
    padding-left: 3%;
    padding-right: 3%;
    font-weight: 500;
    padding-top: 10px;
    font-family: 'Poppins',sans-serif;
  }
  .hover-info p{
    font-size: 14px;
    line-height: 18px;
  }
 
  
}