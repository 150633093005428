body {
  background-color: rgb(244, 253, 255);
  margin: 0;
  top: 0;
  padding: 0;
  padding-right: 0!important;

}

.App{
  overflow-x: hidden;
  width:"100%"
}

.home{
  overflow: auto;
  scroll-snap-type: y mandatory;
}

:root {
  font-family: Poppins;
  --new-blog-input-bg: #ececec;
  --new-blog-input-ft-size: 1.4375rem;
  --new-blog-dialog-caption-ft-size: 1.25rem;
  --yellow-primary: #ffae00;
  --hover-color: #777;
  --error-color: #ee0000;
  --padded-width: calc(1440px - 3rem);
}
@media screen and (max-width: 1100px) {
  :root {
    --new-blog-input-ft-size: 1rem;
    --new-blog-dialog-caption-ft-size: 0.875rem;
  }
}
