.explore-insurance-parent{
    width: 100%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}
.explore-insurance-parent h1{
    text-align: center;
    padding-bottom: 20px;
    font-family: "Lato", sans-serif;
    font-size: 36px;
}
.explore-insurance{
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.explore-insurance-card{
    background: linear-gradient(180.03deg, #F8F8F8 0.03%, #FFFFFF 0.04%, #FFFFFF 60.38%, rgba(216, 216, 216, 0.28) 138.52%);
    box-shadow: 4px 4px 15px 2px #E3E4E4;
    border-radius: 12px;
    margin-left: 20px;
    margin-right: 20px;
    width: 303px;
    height: 404px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.explore-insurance-card img{
    width: 80%;
    
}
.explore-insurance-card h3{
    font-size: 20px;
    padding-top: 20px;
}
.explore-insurance-card button {
    background: #52CCC3;
    padding: 8px 22px;
    border: none;
    color: #ffffff;
    border-radius: 4px;
    font-size: 16px;
}

@media screen and (max-width: 600px) {

    
    .explore-insurance-parent h1{
        color: #000;
        padding-top: 30px;
        font-size: 21px;
     
    }

    .explore-insurance{
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;

        
    }

    .explore-insurance-card{
       
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
       
    }
    .explore-insurance-card img{
         width: 80%;

        
    }
    .explore-insurance-card h3{
        color: #000;
        padding-top: 20px;
    }

    .explore-insurance-card p{
        color: #454545;
    }
    .explore-insurance-card button{
         background: #52CCC3;
         border: none;
         outline: none;
         padding: 8px 20px;
         font-size: 15px;
         border-radius: 25px;
        
    }
}