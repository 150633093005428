.hgftds125{
    margin-top: 150px;
}
.hgftds125 h1{
    text-align: center;
    font-size: 36px;
    padding-top: 20px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
}

/* .hgftds125 button{
    text-align: center;
    font-size: 36px;
    padding-top: 20px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    justify-content: center;
    align-items: center;
} */

.headingForClick{
    background-color: #8ED16F;
    height: 100px;
    width: 300px;
}

.text-center {
    text-align: center;
  }

.hedfa{
    text-align: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}


.calculation-1 {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-family: 'Lato',sans-serif;


}

.cal879{
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cal879 h2{
    font-size: 30px;
    font-weight: 500;

}

.total-assets{
    background: #FF7A7A;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.19);
    display: inline-block;
    color: #fff;
    font-family: 'Lato',sans-serif;
    font-weight: 500;
    font-size: 24px;
    padding: 10px 15px;
    border-radius: 3px;
    margin-top: -15px;
}
.total-lib{
    background: #604FB2;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.19);
    display: inline-block;
    color: #fff;
    font-family: 'Lato',sans-serif;
    font-weight: 500;
    font-size: 24px;
    padding: 10px 15px;
    border-radius: 3px;
    margin-top: -15px;

}

.total-worth{
    background: #8ED16F;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.19);
    display: inline-block;
    color: #fff;
    font-family: 'Lato',sans-serif;
    font-weight: 500;
    font-size: 24px;
    padding: 10px 15px;
    border-radius: 3px;
    margin-top: -15px;
}

.all-parent-div{
    max-width: 1250px;
    margin: 0 auto;
    width: 100%;
    margin-top: 30px;
    height: auto;
    padding-bottom: 70px;
    /* background: #00519C; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 30px;
}

.asset-parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 50px;
    background: #FFFFFF;
    /* box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.19); */
    border-radius: 15px;
    height: 160px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.asset-parent img{
    width: 60px;
    height: 60px;
}

.asset-parent p{
    font-size: 18px;
    font-family: "Lato", sans-serif;
    padding-top: 15px;
  
}
.asset-parent .ghdtb-btn{
    /* background: rgb(164, 196, 245); */
    border: none;
    border-radius: 50%;
    padding: 5px;
    margin-top: 0px;
    position: absolute;
    bottom: -10px;
    
}
.asset-parent .ghdtb-btn img{
    width: 25px;
    height: 25px;


}

.dfghtyu{
    display: flex;
    align-items: center;
    justify-content: center;
}

.willll12{

    font-size: 18px;
    font-weight: 400;
}

.checkbox12{
    margin-left: 10px;
    margin-top: -10px;
}

.sgdgdhbd{
    background-color: #604FB2;
    color: #FFFFFF;
    border: none;
    outline: none;
    font-family: 'Lato',sans-serif;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 3px;
}

.dfghtyu input{
    background-color: #604FB2;
    color: #604FB2;

}


@media screen and (max-width: 600px) {

    .asset-parent{
        width: 90px;
        height: 90px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 12px;
        margin-bottom: 30px;
    }

    .asset-parent img{
        width: 30px;
        height: 32px;
        margin-top: 10px;
    }
    .asset-parent p{
        font-size: 12px;
        padding-top: 10px;
        text-align: center;
    }

    .asset-parent .ghdtb-btn{
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-top: 10px;
        position: absolute;
        bottom: -10px;
        display: flex;
        justify-content: center;
    
        
    }
    .asset-parent .ghdtb-btn img{
        width: 18px;
        position: absolute;
        margin-top: 3px;
        height: 16px;
    
    }
    .hgftds125 h1{
        font-size: 29px;
        line-height: 32px;
        font-weight: 600;
        font-family: "Lato", sans-serif;
    }

    
.calculation-1 {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 23px;
    font-family: 'Lato',sans-serif;


}

.cal879{
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cal879 h2{
    font-size: 17px;
    font-weight: 500;

}

.total-assets{
    background: #FF7A7A;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.19);
    display: inline-block;
    color: #fff;
    font-family: 'Lato',sans-serif;
    font-weight: 500;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 3px;
}
.total-lib{
    background: #604FB2;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.19);
    display: inline-block;
    color: #fff;
    font-family: 'Lato',sans-serif;
    font-weight: 500;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 3px;

}

.total-worth{
    background: #8ED16F;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.19);
    display: inline-block;
    color: #fff;
    font-family: 'Lato',sans-serif;
    font-weight: 500;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 3px;
}
    
}