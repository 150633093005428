.risk-modal{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 30000;
    overflow-y: scroll;
    
}

.riskfactor-modal-box{
    max-width: 900px;
    width: 100%;
    background: #ffffff;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 50000;
    position: relative;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 2%;
    padding-right: 2%;
    height: auto;
}  



@media screen and (max-width: 600px) {

    .riskfactor-modal-box{
        border-radius: 0;
        padding-left: 0;
       
    }
    
}