.loans-poster {
    width: 100%;
    /* height: calc(100vh - 47px); */
    height: 80vh;
  
    display: flex;
    position: relative;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .loans-poster-left {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    
  }
  
  .loans-poster-left h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 38px;
    font-weight: 500;
  }
  
  .loans-poster-left h1 span{
     color:#FF5070;
     font-weight: 600;
  }
  .loans-poster-left p {
    /* margin: 5px; */
    margin-top: 10px;
    width: 80%;
    color: #454545;
    font-size: 20px;
    padding-right: 10%;
  }
 
  
  
  .loans-poster-right{
    display: flex;
    justify-content: center;
  }
  .loans-poster-right img{
    margin-top: 120px;
    width: 90%;
    /* height: 50%; */
  }


.apply-now-btn-loan{
    background: #FF4F6F;
    border: none;
    /* padding: 15px 29px; */
    color: #fff;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    border-radius: 4px;
    display: inline-block;
    margin-top: 20px;
    width: 200px;
    height: 55px;

}

.loan-mobile{
  display: none;
}

.middle-heading-loan{
  font-size: 36px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width : 600px) {

  .loans-poster{
    
    height: auto;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
  }
  .loans-poster-left{
    padding-left: 0;
    width: 100%;
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
     
  }
  .loans-poster-left h1{
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    
    color: #000;
    padding-top: 15px;

  }
  .loans-poster-left h1 span{
    text-transform: uppercase;
    font-weight: 600;
  }

  .loans-poster-left p{
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    
    color: #454545;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
  }
  .loans-poster-right{
    display: none;
  }

  .loan-mobile{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .loan-mobile img{
   
    height: 250px;
  }

  .apply-now-btn-loan{
    
    width: auto;
    height: auto;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 25px;
  }
  .middle-heading-loan{
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }
}
