.investment-parent {
  background-color: #ffffff;
   margin-top: -100px; 
  height: auto;
  padding-bottom: 50px;
  background-attachment: fixed;
}

@media screen and (max-width: 1300px) {
  .investment-parent {
    height: auto;
    padding-bottom: 10px;
    margin-top: 0px;
    /* margin-top: -700px; */
  }
}

@media screen and (max-width: 600px) {
  .investment-parent {
    height: auto;
    padding-bottom: 10px;
    margin-top: 0px;
  }
}
.investment-div {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  /* margin-top: 10px; */
  padding-top: 10px;
  padding-bottom: 50px;
}

.invest-tag {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 5%;
}
.invest-tag span {
  color: #8d81c9;
  font-weight: 600;
}

.invest-sub-tag {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  /* identical to box height */

  color: #797979;
  padding-top: 15px;
}

.invest-parent {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-left: 5%;
}

.invest-card {
  background: rgba(223, 216, 253, 0.2);
  box-shadow: 9px 5px 7px rgba(96, 79, 178, 0.11);
  border-radius: 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 20px;
  width: 300px;
  height: 510px;
  padding: 20px;
  transition: 0.3s;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.invest-card:hover {
  box-shadow: 9px 5px 50px rgba(96, 79, 178, 0.35);
  transform: translateX(15px);
}

.invest-img-box {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  height: 182px;
}

.box-heading {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  padding-top: 30px;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;

  color: #000000;
}

#box-description {
  font-style: normal;
  font-weight: 400;

  font-size: 18px;
  line-height: 32px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;

  color: #797979;
}

@media screen and (max-width: 1000px) {
  .invest-parent {
    flex-direction: column;
  }
  .invest-card {
    flex-direction: row;
    margin-bottom: 25px;
    width: 95%;
    height: auto;
  }
  .invest-card:hover {
    box-shadow: 9px 5px 50px rgba(96, 79, 178, 0.35);
    transform: translateX(15px);
  }
  .invest-img-box {
    width: 40%;
    margin-left: 5px;
  }
  .invest-img-box img {
    object-fit: contain;
  }
  .near-content {
    width: 60%;
  }
  .box-heading {
    font-size: 25px;
    line-height: 35px;
    padding: 0px 15px 0px 15px;
    margin-top: 20px;
  }
  #box-description {
    font-size: 15px;
    line-height: 20px;
    padding: 0px 15px 0px 15px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {

  .near-content {
    margin-left: 22px !important;
  }
  .invest-card:hover {
    display: none;
  }
  .invest-tag {
    font-size: 27px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }
  .invest-tag span {
    /* color: #FFC400; */
    font-weight: 600;
  }
  .invest-sub-tag {
    padding-top: 5px;
    color: #7e7c79;
  }
  .box-heading {
    font-size: 25px;
    line-height: 35px;
    padding: 0px 15px 0px 15px;
    margin-top: 20px;
  }
}
