.profile-form {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-content: center;
}

.profile-form-head {
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    align-self: center;
    margin-bottom: 30px;
    padding-top: 10px;
}

.profile-form input, select {
    background: #fafafc;
    border: 1px solid #E7ECF4;
    border-radius: 5px;
    padding: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;;
    color: #000;
    margin: 5px;
    margin-top: 15px;
    outline: none;
    transition: 0.3s;
}
.profile-form input:focus, select:focus{

    border: 1px solid hsl(264, 60%, 45%);

}

.gender-gap{
    margin-left: 20px;
}

.ghtmk{
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 80%;
    z-index: -1;
}
.risk-inputs{
    width: 40%;
}
.risk-inputs input {
    width: 100%;
}

.profileform-dropdowns {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.profile-form select {
    width: 25%;
    
}

.profile-form option {
    color: #000000;
}

.profile-form button {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    color: #FFC727;
    background: #37474F;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    width: 200px;
    height: 55px;
    margin-top: 25px;
    margin-left: 0px;
}

.riskfactor-question-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.riskfactor-heading {
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    margin-top: 15px;
    line-height: 30px;
    margin-bottom: 20px;

}

.riskfactor-subheading {
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 15px;
}

.riskfactor-question {
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.riskfactor-options {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-bottom: 30px;
}

.selected-option {
    background: #00519c;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 5px;
    cursor: pointer;
}

.option {
    background: rgba(196, 196, 196, 0.39);
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.option :hover {
    transform: scale(1.05);
}

.riskfactor-button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.riskfactor-button-row button {
    background: #37474F;
    border-radius: 6px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    color: #FFC727;
    border: none;
    margin: 10px;
    cursor: pointer;
    padding: 6px 25px;
}

.riskfactor-button-row button :disabled {
    opacity: 70%;
}

.riskfactor-close-button {
    position: absolute;
    top: 2px;
    right: 10px;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.riskfactor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: scroll;
    /* overflow: scroll; */
}

.riskfactor-container-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    /* overflow: scroll;
    background-color: #00519c; */
}

.riskfactor-score-board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F3F7F8;
    border-radius: 20px;
    padding: 10px;
    width: fit-content;
}

.riskfactor-score {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 66px;
    text-align: center;
}

.riskfactor-score-comment {
    background: #DBE3E6;
    border-radius: 5px;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    width: fit-content;
    padding: 5px;
}

.riskfactor-suggestion {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
}

.riskfactor-retry {
    background: #FF7666;
    border-radius: 6px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 41px;
    color: #FFFFFF;  
    width: fit-content;  
    padding: 6px 25px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
}

.riskfactor-btn-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: fit-content;
}

.riskfactor-btn-row button {
    background: #37474F;
    border-radius: 6px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 41px;
    color: #FFC727;
    padding: 6px 25px;
    border: none;
    cursor: pointer;
    margin: 5px;
}

.riskfactor-discover {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.riskfactor-discover-head {
    width: fit-content;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 53px;
    color: #101010;
}

.riskfactor-grid {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
}

.riskfactor-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 20px;
    background: #FFF4F4;
    border-radius: 16px;
    margin-bottom: 10px;
    /* margin: 5px; */
}

.riskfactor-card img {
    height: 40px;
    width: 40px;
    padding: 10px;
}

.riskfactor-card div {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    width: fit-content;
    white-space: nowrap;
}






.riskfactor-close-button{
    background: #eae8e8;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .profile-form input {
        width: 50%;
    }
    .profile-form select {
        width: auto;
    }
    .riskfactor-score {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 50px;
        text-align: center;
    }

    .riskfactor-score-comment {
        background: #DBE3E6;
        border-radius: 5px;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        line-height: 30px;
        width: fit-content;
        padding: 5px;
    }

    .riskfactor-suggestion {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }

    .riskfactor-btn-row button {
        background: #37474F;
        border-radius: 6px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 41px;
        color: #FFC727;
        padding: 3px 8px;
        border: none;
        cursor: pointer;
        margin: 3px;
    }

    .riskfactor-retry {
        background: #FF7666;
        border-radius: 6px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 41px;
        color: #FFFFFF;  
        width: fit-content;  
        padding: 6px 18px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
    }
}



.hjkgkujf{
    display: none;
}
@media screen and (max-width:600px) {

    .riskfactor-score {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 66px;
        text-align: center;
    }

    .ghtmk{
        display: none;
        
    }
    .profile-form-head{
        display: flex;
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 5px;
        padding-left: 5%;
       
    }
    .hjkgkujf{
        display: block;
        height: 150px;
        margin-right: 3%;
    }
    .risk-inputs{
        width: 100%;
    }
    .risk-inputs input{
        width: 90%;
        margin-left: 5%;
        font-size: 18px;
    }
    .profileform-dropdowns{
        margin-left: 5%;
    }
    .profile-form button{
        margin-left: 5%;
        font-size: 20px;
        width: 150px;
        height: 50px;
    }

    .riskfactor-suggestion {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }
    
}