.perform-report{
    
    color: #fff;
    max-width: 862px;
    width: 73%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    font-size: 22px;
    font-weight: 500;
    border-radius: 2px;
    margin-top: -40px;
    margin-bottom: 20px;
    visibility: hidden;

}

.perform-report.best{
    background: #39B54A;

}

.riskfactor-score{
    text-size-adjust: 10px;
}

.perform-report.avg{
    background: rgb(101, 207, 53);
}

@media screen and (max-width: 800px) {
    .perform-report{
    
        color: #fff;
        max-width: 862px;
        width: 73%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
        font-size: 22px;
        font-weight: 500;
        border-radius: 2px;
        margin-top: -55px;
        margin-bottom: -20px;
        visibility: hidden;
    
    }
}